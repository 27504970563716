import React, { Component } from 'react';
import { translate } from 'react-i18next';
import './MediaPickerModal.css';

import axios from 'axios';
import {
    Panel,
    Col,
    Row,
    Table,
    Button,
    Modal,
    FormControl,
    Form,
    FormGroup,
    ControlLabel,
    ProgressBar
} from 'react-bootstrap';
import HeaderFooterPanel from '../components/HeaderFooterPanel';
import TemplateMediaPicker from '../components/TemplateMediaPicker';
import SpinnerButton from '../components/SpinnerButton';
import config from '../config';
import qs from 'qs';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';

class MediaPickerModal extends Component {
    constructor(props) {
        super(props);

        this.handleHide = this.handleHide.bind(this);

        this.state = {
            show: false,
            title: 'Replace Media',
            limit: 10,
            files: [],
            search: '',
            organisationUUID: '',
            filePercentages: [],
            uploading: false,
            isCopyingMedia: false

        };
    }

    handleHide() {
        this.props.onHide();
        // this.setState({
        //     itemsSelected: []
        // });
    }

    handleChooseClicked() {
        this.props.onChoose(this.state.itemsSelected);
        this.setState({
            itemsSelected: []
        });
    }

    componentDidMount() {}

    componentWillReceiveProps(newProps) {
        this.setState(
            {
                show: newProps.show,
                title: newProps.title,
                organisationUUID: newProps.organisationUUID,
                mediaLibraryUUID: newProps.mediaLibraryUUID,
                mediaToReplaceUUID: newProps.mediaToReplaceUUID,
                files: []
            },
            () => {
                // this.loadOrganisationMediaLibrariesFromServer();
            }
        );
    }


    onDrop(files) {
        const { mediaLibraryUUID, mediaToReplaceUUID, organisationUUID} = this.props;

        let self = this;

        this.setState({
            files
        });

        const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

        function uploadFile(file) {
            return new Promise(function(resolve, reject) {
                axios
                    .post(
                        `${
                            config.baseURL
                        }/medialibraries/${mediaLibraryUUID}/media/${mediaToReplaceUUID}`,
                        {
                            fileName: file.name,
                            fileSize: file.size
                        }
                    )
                    .then(function(result) {
                        let key = result.data.key;
                        let policy = result.data.policy;
                        let signature = result.data.signature;
                        let contentType = result.data.contentType;

                        var options = {
                            headers: {
                                'Content-Type': file.type
                            }
                        };

                        var formData = new FormData();

                        formData.append('key', key);
                        formData.append(
                            'AWSAccessKeyId',
                            'AKIAIOLWPARNSYK27RYA'
                        );
                        formData.append('acl', 'public-read');
                        formData.append('success_action_status', '201');
                        formData.append('policy', policy);
                        formData.append('signature', signature);
                        formData.append('Content-Type', contentType);
                        formData.append('file', file);

                        let filePercentages = self.state.filePercentages;
                        filePercentages[file.name] = 0;
                        self.setState({ uploading: true, filePercentages });

                        return axios({
                            url:
                                'https://mylook-player-content.s3-eu-west-1.amazonaws.com/',
                            method: 'POST',
                            data: formData,
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: progressEvent => {
                                let percentCompleted = Math.round(
                                    (progressEvent.loaded * 100) /
                                        progressEvent.total
                                );
                                let filePercentages =
                                    self.state.filePercentages;
                                filePercentages[file.name] = percentCompleted;
                                self.setState({ filePercentages });
                            }
                        });
                    })
                    .then(function(result) {
                        let filePercentages = self.state.filePercentages;
                        filePercentages[file.name] = 100;
                        self.setState({ uploading: true, filePercentages });
                        resolve();
                    })
                    .catch(function(err) {
                        console.log(
                            `Error uploading file: ${file} error: ${err}`
                        );
                        let filePercentages = self.state.filePercentages;
                        filePercentages[file.name] = 'error';
                        self.setState({ uploading: true, filePercentages });
                        reject('error');
                    });
            });
        }

        const promiseSerial = files =>
            files.reduce(function(promise, file) {
                return promise.then(function(result) {
                    return Promise.all([delay(500), uploadFile(file)]);
                });
            }, Promise.resolve());

        promiseSerial(files).then(() => {
            console.log('Uploaded all Files');

            
            this.props.onMediaUploaded();
        });
    }

    
    render() {
        const { t } = this.props;

        let dropzoneRef;

        return (
            <div className="MediaPickerModal">
                <Modal
                    show={this.state.show}
                    onHide={this.handleHide}
                    container={this}
                    aria-labelledby="contained-modal-title"
                    dialogClassName="MediaPickerModal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title">
                            {t('MediaPickerModal.title')}{' '}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ margin: '0px', padding: '0px' }}>
                        {/* <h4>{t('MediaPickerModal.about')}</h4> */}
                        <form onSubmit={this.handleSubmit}>
                                                                    <Row bsClass="form-row">
                                                                        <Col sm={12}>
                                                                            <FormGroup bsSize="sm">
                                                                            
                        
                                                                                <h5>
                                                                                    {t(
                                                                                        'MediaLibraryUpload.select_from_computer'
                                                                                    )}
                                                                                </h5>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary btn-block"
                                                                                    onClick={() => {
                                                                                        dropzoneRef.open();
                                                                                    }}
                                                                                >
                                                                                    {t(
                                                                                        'MediaLibraryUpload.browse_for_media'
                                                                                    )}
                                                                                </button>
                                                                                <h5>
                                                                                    {t(
                                                                                        'MediaLibraryUpload.drag_message'
                                                                                    )}
                                                                                </h5>
                                                                                <Dropzone
                                                                                    ref={node => {
                                                                                        dropzoneRef = node;
                                                                                    }}
                                                                                    className="upload-drop-zone"
                                                                                    onDrop={this.onDrop.bind(
                                                                                        this
                                                                                    )}
                                                                                    multiple={false}
                                                                                >
                                                                                    <p>
                                                                                        {t(
                                                                                            'MediaLibraryUpload.dropmediahere'
                                                                                        )}
                                                                                    </p>
                                                                                </Dropzone>
                        
                                                                                {this.state.files
                                                                                    .length ? (
                                                                                    <div>
                                                                                        <h3>
                                                                                            {t(
                                                                                                'MediaLibraryUpload.file_status'
                                                                                            )}
                                                                                        </h3>
                                                                                        {this.state.files.map(
                                                                                            f => {
                                                                                                if (
                                                                                                    this
                                                                                                        .state
                                                                                                        .filePercentages[
                                                                                                        f
                                                                                                            .name
                                                                                                    ] ===
                                                                                                    'error'
                                                                                                ) {
                                                                                                    return (
                                                                                                        <div
                                                                                                        >
                                                                                                            <ControlLabel
                                                                                                            >{`${t(
                                                                                                                'MediaLibraryUpload.error'
                                                                                                            )}:  ${t(
                                                                                                                'MediaLibraryUpload.error_reason'
                                                                                                            )} (${
                                                                                                                f.name
                                                                                                            })`}</ControlLabel>
                        
                                                                                                            <ProgressBar
                                                                                                                bsStyle="danger"
                                                                                                                key={
                                                                                                                    f.name
                                                                                                                }
                                                                                                                now={
                                                                                                                    100
                                                                                                                }
                                                                                                                label={t(
                                                                                                                    'MediaLibraryUpload.error_uploading'
                                                                                                                )}
                                                                                                            />
                                                                                                        </div>
                                                                                                    );
                                                                                                }
                                                                                                return (
                                                                                                    <div
                                                                                                    >
                                                                                                        <ControlLabel
                                                                                                        >{`${t(
                                                                                                            'MediaLibraryUpload.uploading'
                                                                                                        )} ${
                                                                                                            f.name
                                                                                                        } - ${this
                                                                                                            .state
                                                                                                            .filePercentages[
                                                                                                            f
                                                                                                                .name
                                                                                                        ] ||
                                                                                                            '0'}%`}</ControlLabel>
                        
                                                                                                        <ProgressBar
                                                                                                            bsStyle="success"
                                                                                                            key={
                                                                                                                f.name
                                                                                                            }
                                                                                                            now={
                                                                                                                this
                                                                                                                    .state
                                                                                                                    .filePercentages[
                                                                                                                    f
                                                                                                                        .name
                                                                                                                ] ||
                                                                                                                0
                                                                                                            }
                                                                                                            label={`${this
                                                                                                                .state
                                                                                                                .filePercentages[
                                                                                                                f
                                                                                                                    .name
                                                                                                            ] ||
                                                                                                                '0'}%`}
                                                                                                        />
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </div>
                                                                                ) : null}
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </form>
                    </Modal.Body>
                    <Modal.Footer>
                        
                        <div className="btn-toolbar pull-right">
                            <Button onClick={this.handleHide}>
                                {t('MediaPickerModal.close')}
                            </Button>
                          
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default translate('translations')(MediaPickerModal);
