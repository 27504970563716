import React, { Component, Fragment } from 'react';
import Navigation from '../components/Navigation';
import { translate } from 'react-i18next';
import axios from 'axios';
import {
    Panel,
    Col,
    Row,
    Button,
    FormControl,
    Form,
    FormGroup
} from 'react-bootstrap';
import PaginationPanel from '../components/PaginationPanel';
import TooltipButton from '../components/TooltipButton';
import config from '../config';
import qs from 'qs';
import SnippetRulesTable from '../components/SnippetRulesTable';
import { Link } from 'react-router-dom';
import Dialog from 'react-bootstrap-dialog';
import ReactPaginate from 'react-paginate';

import './SnippetRules.css';

class SnippetRules extends Component {
    constructor(props) {
        super(props);
        const { uuid } = this.props.match.params;

        this.state = {
            user: props.user,
            userIsAuthorized: props.userIsAuthorized,
            snippet: { name: '', uuid: '' },
            page: props.page,
            rulegroups: null,
            limit: 1000,
            pageCount: 0,
            selected: props.selected -1
        };
    }

    deleteRule(rulegroup, rule) {
        var self = this;
        axios
            .delete(
                `${config.baseURL}/snippets/${this.state.snippet.uuid}/rulegroups/${
                    rulegroup.uuid
                }/rules/${rule.uuid}`
            )
            .then(function(result) {
                self.loadSnippetRuleGroupsFromServer();
            })
            .catch(function(err) {
                console.log(`Error deleting rule: ${rule} error: ${err}`);
            });
    }

    editRule(rulegroup, rule) {
        const {
            uuid,
            playerUUID,
            pageUUID,
            snippetUUID
        } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;

        let link = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/snippets/${snippetUUID}/rulegroups/${rulegroup.uuid}/rules/${
            rule.uuid
        }/edit?rel=player`;

        if (rel === 'snippet') {
            link = `/organisations/${uuid}/snippets/${snippetUUID}/rulegroups/${rulegroup.uuid}/rules/${
                rule.uuid
            }/edit?rel=snippet`;
        } else if (rel === 'page') {
            link = `/organisations/${uuid}/pages/${pageUUID}/snippets/${snippetUUID}/rulegroups/${rulegroup.uuid}/rules/${
                rule.uuid
            }/edit?rel=page`;
        }

        this.props.history.push(link);
    }

    componentDidMount() {
        this.loadSnippetFromServer();
        this.loadSnippetRuleGroupsFromServer();
    }

    loadSnippetFromServer() {
        const { snippetUUID } = this.props.match.params;

        let self = this;
        axios
            .get(`${config.baseURL}/snippets/${snippetUUID}`, {})
            .then(function(response) {
                self.setState({ snippet: response.data.snippet });
                self.loadOrganisationInformation();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadOrganisationInformation() {
        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name
                });

                const parsed = qs.parse(
                    self.props.location.search.substring(1)
                );
                const rel = parsed.rel;
                if (rel === 'player') {
                    self.loadPlayerFromServer();
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadPlayerFromServer() {
        var self = this;
        const { playerUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/players/${playerUUID}`, {})
            .then(function(response) {
                self.setState({
                    player_name: response.data.player.name
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadSnippetRuleGroupsFromServer() {
        const { snippetUUID } = this.props.match.params;

        let self = this;
        axios
            .get(`${config.baseURL}/snippets/${snippetUUID}/rulegroups`, {})
            .then(function(response) {
                if (
                    response.data.rulegroups &&
                    response.data.rulegroups.length > 0
                ) {
                    self.setState(
                        { rulegroups: response.data.rulegroups },
                        () => {
                            self.loadRuleGroupRulesFromServer();
                        }
                    );
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    handleDeleteRuleGroupClicked(rulegroup) {
        var self = this;
        axios.delete(`${config.baseURL}/snippets/${this.state.snippet.uuid}/rulegroups/${rulegroup.uuid}/`)
            .then(function(result) {
                self.loadSnippetRuleGroupsFromServer();

            }).catch(function(err) {
                console.log(`Error deleting rulegroup: ${JSON.stringify(rulegroup)} error: ${err}`);
            });

    }
    handleChange = (event, rulegroup) => {
        const { snippetUUID } = this.props.match.params;

        const newOperator = event.target.value;

        let self = this;

        axios
            .patch(
                `${config.baseURL}/snippets/${snippetUUID}/rulegroups/${
                    rulegroup.uuid
                }`,
                {
                    name: rulegroup.name,
                    operator: newOperator
                }
            )
            .then(function(response) {
                self.loadSnippetRuleGroupsFromServer();
            })
            .catch(function(error) {
                console.log(error);
            });
    };

    handleAddRuleGroup() {

        const { snippetUUID } = this.props.match.params;

        const ruleGroupProperties = {
            name: 'Rule Group',
            operator: 'AND'
        }

         let self = this;
            axios
                .post(
                    `${config.baseURL}/snippets/${
                        snippetUUID
                    }/rulegroups`,
                    ruleGroupProperties
                )
                .then(function(response) {
                    self.loadSnippetRuleGroupsFromServer();
                })
    }

    loadRuleGroupRulesFromServer() {
        const { snippetUUID } = this.props.match.params;

        let self = this;
        let rulegroups = this.state.rulegroups;

        function getAllRules(rgs){
            return Promise.all(rgs.map(fetchRules));
        }
          
        function fetchRules(rulegroup) {

            return axios
              .get(`${config.baseURL}/snippets/${snippetUUID}/rulegroups/${rulegroup.uuid}/rules`,
                {
                    params: {
                        page: self.state.page,
                        limit: self.state.limit
                    }
                }
            )
            .then(function(response) {
            
                rulegroup.rules = response.data.rules;
                return {
                    success: true,
                    rules: response.data.rules
                };
            })
            .catch(function(error) {
                return { success: false };
            });
        }

        getAllRules(rulegroups).then((resp) => {

            console.log(`Resp: ${JSON.stringify(resp)}`);
            
            self.setState({
                rulegroups
            });

        }).catch((e)=>{console.log(e)})
    }

    handlePageClick = data => {
        let selected = data.selected + 1;

        const url = new URL(window.location.href);
        url.searchParams.set('page', selected);
        window.history.pushState(null, null, url);

        this.setState({ page: selected, selected: data.selected }, () => {
            this.loadPlayerAttributesFromServer();
        });
    };

    render() {
        const { t } = this.props;
        const {
            uuid,
            playerUUID,
            pageUUID,
            snippetUUID

        } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;
        document.title = `MySign Digital Screen Solutions | ${t(
            'SnippetRules.title'
        )}`;

        let ruleGroupPanels = null;
        if (this.state.rulegroups && this.state.rulegroups.length > 0) {

            console.log(`RuleGroups: ${this.state.rulegroups}`);

            ruleGroupPanels = this.state.rulegroups.map((rulegroup, i) => {

                const {
                    uuid,
                    playerUUID,
                    pageUUID,
                    snippetUUID,
                } = this.props.match.params;
        
                const parsed = qs.parse(this.props.location.search.substring(1));
                const rel = parsed.rel;
    
                console.log(`RuleGroup: ${JSON.stringify(rulegroup)}`);
    
                let createRuleLink = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/snippets/${snippetUUID}/rulegroups/${rulegroup.uuid}/rules/create?rel=player`;

                if (rel === 'snippet') {
                    createRuleLink = `/organisations/${uuid}/snippets/${snippetUUID}/rulegroups/${rulegroup.uuid}/rules/create?rel=snippet`;
                } else if (rel === 'page') {
                    createRuleLink = `/organisations/${uuid}/pages/${pageUUID}/snippets/${snippetUUID}/rulegroups/${rulegroup.uuid}/rules/create?rel=page`;
                }
    
                return (
                    <>
                    <PaginationPanel
                                heading={
                                    <Form inline>
                                        <FormGroup>
                                            <h4
                                                style={{
                                                    display: 'inline'
                                                }}
                                            >
                                                {t('SnippetRules.show_if')}
                                            </h4>{' '}
                                            <FormControl
                                                className="select input-sm"
                                                componentClass="select"
                                                placeholder="Choose..."
                                                value={
                                                    rulegroup
                                                        .operator
                                                }
                                                onChange={(e) => this.handleChange(e, rulegroup)}
                                            >
                                                <option value="AND">
                                                    {t('SnippetRules.all')}
                                                </option>
                                                <option value="OR">
                                                    {t('SnippetRules.any')}
                                                </option>
                                            </FormControl>{' '}
                                            <h4
                                                style={{
                                                    display: 'inline'
                                                }}
                                            >
                                                {t(
                                                    'SnippetRules.of_the_rules_below_apply'
                                                )}
                                            </h4>
                    
                                        </FormGroup>
                                        {this.state.userIsAuthorized(
                                                this.state.user,
                                                [
                                                    'staff_super_admin',
                                                    'staff_admin',
                                                    'organisation_admin',
                                                    'organisation_user'
                                                ]
                                            ) ? (
                                            <div className="buttonContainer">
                                                <Link
                                                    type="submit"
                                                    className="btn btn-primary text-right addRule"
                                                    to={createRuleLink}
                                                
                                                >
                                                    <em className="fa fa-plus fa-fw" />{' '}
                                                    {t('SnippetRules.add_rule')}
                                                </Link> {' '}
                                                <TooltipButton
                                    tooltip={t(
                                        'SnippetRules.deleterulegroup'
                                    )}
                                    className="btn-danger "
                                    onClick={() =>
                                        this.handleDeleteRuleGroupClicked(rulegroup)
                                    }
                                >
                                    <em className="fa fa-trash" />
                                </TooltipButton>
                                            </div>
                                            ) : null}
                                    </Form>
                                    
                                }
                                pagination={
                                    (null)
                                }
                            >
                                        {/* console.log(`SnippetRulesTable: ${JSON.stringify(rulegroup)}`); */}
                                        <SnippetRulesTable
                                            userIsAuthorized={
                                                this.state.userIsAuthorized
                                            }
                                            user={this.state.user}
                                            rulegroup={rulegroup}
                                            rules={rulegroup.rules}
                                            snippetUUID={snippetUUID}
                                            onDelete={this.deleteRule.bind(this)}
                                            handleEditClicked={this.editRule.bind(
                                                this
                                            )}
                                            
                                        />
                                    </PaginationPanel>
                                    { i < this.state.rulegroups.length - 1 ? (

                                             <span className="label label-primary logicSeperator">
                                               <em className="fa fa-plus fa-fw logicSeperator" /> AND
                                            </span>

                                    ) : (null)}
                                    
                                    </>
                                   
                );
        }
        
            )};
        return (
            <div className="SnippetRules">
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('SnippetRules.title')}
                                    {' - "'}
                                    {this.state.snippet.name}
                                    {'" '}
                                    {rel === 'player' ? (
                                        <small>
                                            {this.state.player_name}{' '}
                                            <small
                                                style={{
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {this.state.organisation_name}
                                            </small>
                                        </small>
                                    ) : (
                                        <small>
                                            {this.state.organisation_name}
                                        </small>
                                    )}
                                    {this.state.userIsAuthorized(
                                        this.state.user,
                                        [
                                            'staff_super_admin',
                                            'staff_admin',
                                            'organisation_admin',
                                            'organisation_user'
                                        ]
                                    ) ? (
                                        <TooltipButton
                                        id=""
                                        className="btn btn-success"
                                        tooltip={t('SnippetRules.add_rule_group')}
                                        onClick={() =>
                                            this.handleAddRuleGroup()
                                        }
                                    >
                                        <em className="fa fa-plus fa-fw" />{t('SnippetRules.add_rule_group')}
                                    </TooltipButton>

                                    ) : null}
                                     {rel === 'player' ? (
                                        <>
                                        <a
                                            id="previewButton"
                                            type="submit"
                                            className="btn btn-success header-button"
                                            target="_blank"
                                            href={`${
                                                config.playersBaseURL
                                            }/mediaserver/unit.php?playerUUID=${playerUUID}&preview=1`}
                                        >
                                            <em className="fa fa-eye fa-fw" />{' '}
                                            {t('Player.preview_player')}
                                        </a>

                                        <a
                                            id="previewButton"
                                            type="submit"
                                            className="btn btn-warning header-button"
                                            target="_blank"
                                            href={`${
                                                config.playersBaseURL
                                            }/mediaserver/unit.php?playerUUID=${playerUUID}&preview=1&ignoreRules=1`}
                                        >
                                            <em className="fa fa-eye fa-fw" />{' '}
                                            {t('Player.preview_player_all')}
                                        </a>

                                        </>
                                    ) : null}
                                </h1>

                                <ol className="breadcrumb">
                                    {rel === 'player' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players`}
                                                >
                                                    {t(
                                                        'SideNavigation.players'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}`}
                                                >
                                                    {t(
                                                        'OrganisationPlayer.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/`}
                                                >
                                                    {t('SideNavigation.pages')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}?rel=player`}
                                                >
                                                    {' '}
                                                    {t('Page.title')}
                                                </Link>
                                            </li>{' '}

                                            <li className="active">
                                                <i className="fas fa-clipboard-list" />{' '}
                                                {t('SnippetRules.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}

                                    {rel === 'snippet' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />
                                                <Link
                                                    to={`/organisations/${uuid}/snippets`}
                                                >
                                                    {' '}
                                                    {t(
                                                        'SideNavigation.globalsnippets'
                                                    )}
                                                </Link>
                                            </li>{' '}
                                            
                                            <li className="active">
                                                <i className="fas fa-clipboard-list" />{' '}
                                                {t('SnippetRules.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                    {rel === 'page' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages`}
                                                >
                                                    {t(
                                                        'SideNavigation.globalpages'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages/${pageUUID}?rel=page`}
                                                >
                                                    {t('Page.title')}
                                                </Link>
                                            </li>

                                            <li className="active">
                                                <i className="fas fa-clipboard-list" />{' '}
                                                {t('SnippetRules.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                </ol>

                                {ruleGroupPanels}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(SnippetRules);
