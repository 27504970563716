import React, { Component } from 'react';
import { Table, FormControl } from 'react-bootstrap';
import { translate } from 'react-i18next';
import Moment from 'moment';
import './OrganisationInvitesTable.css';
import TooltipLink from './TooltipLink';
import Dialog from 'react-bootstrap-dialog';
import TooltipButton from './TooltipButton';

class OrganisationInvitesTable extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() { }


	getHighestRole(user, uuid) {

		const roleMapping = {
			"staff_super_admin": 1,
			"staff_admin": 2,
			"staff_engineer": 3,
			"organisation_admin": 4,
			"organisation_user": 5,
			"organisation_publisher": 6
		};

		if (user.organisationRoles && user.organisationRoles[uuid]) {
			const role = user.organisationRoles[uuid][0];
			console.log(`user: ${user.uuid} role: ${role}`);
			return roleMapping[role] || -1;
		}

		console.log("Returning -1");
		return -1;
	}

	didConfirmDelete(invite) {
		this.props.onDelete(invite);
	}

	handleResendClicked(invite) {
		this.props.onResendInvite(invite);
	}

	handleDeleteClicked(invite) {
		const { t } = this.props;

		this.dialog.show({
			title: t('Dialog.warning'),
			body: (
				<div>
					{t('Dialog.sure_delete_invite')}<br/>
					<ul><li>{invite.email}</li></ul>
				</div>
			),
			actions: [
				Dialog.Action(
					t('Dialog.cancel'),
					() => console.log('Cancel!'),
					'btn-default'
				),
				Dialog.Action(
					t('Dialog.delete'),
					() => this.didConfirmDelete(invite),
					'btn-danger'
				)
			],
			bsSize: 'large',
			onHide: dialog => {
				dialog.hide();
			}
		});
	}

	render() {
		const { t, uuid, invites, user } = this.props;

		let inviteRows = invites.map((invite, index) => {

			return (
				<tr key={index}>
					<td width="75">{invite.email}</td>
					<td width="75">
						{invite.role}
					</td>
					<td width="75">
						 {Moment(invite.expires).format('YYYY-MM-DD HH:mm')}
					</td>
					<td width="20%" className="td-center">
						<TooltipButton
							id="resendButton"
							style={{ 'marginLeft': '10px' }}
							className="btn-primary"
							tooltip={t(
								'OrganisationInvitesTable.resendInvite'
							)}
							onClick={() => this.handleResendClicked(invite)}
						>
							<em className="fa fa-paper-plane" />{' '}
							{t('OrganisationInvitesTable.resendInvite')}
						</TooltipButton>
						<TooltipButton
							id="deleteButton"
							style={{ 'marginLeft': '10px' }}
							className="btn-danger"
							tooltip={t(
								'OrganisationInvitesTable.removeInvite'
							)}
							onClick={() => this.handleDeleteClicked(invite)}
						>
							<em className="fa fa-trash" />{' '}
							{t('AttributePlayersTable.remove')}
						</TooltipButton>
					</td>
				</tr>
			);
		});

		return (
			<div id="users">
				<Dialog
					ref={el => {
						this.dialog = el;
					}}
				/>
				<Table striped bordered condensed hover>
					<thead>
						<tr>
							<th>{t('OrganisationInvitesTable.email')}</th>
							<th className="hidden-xs">{t('OrganisationInvitesTable.role')}</th>
							<th className="hidden-xs">{t('OrganisationInvitesTable.expires')}</th>

							{this.props.userIsAuthorized(user, ['staff_super_admin', 'staff_admin', 'organisation_admin']) ? (
								<th>
									<em className='fa fa-cog' />{' '}
								</th>
							) : null}
						</tr>
					</thead>
					<tbody>{inviteRows}</tbody>
				</Table>
			</div>
		);
	}
}

export default translate('translations')(OrganisationInvitesTable);
