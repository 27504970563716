import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import Navigation from '../components/Navigation';
import PlayerPagesTable from '../components/PlayerPagesTable';
import PlayerPickerModal from '../components/PlayerPickerModal';
import PaginationPanel from '../components/PaginationPanel';
import config from '../config';
import { Link } from 'react-router-dom';
import Dialog from 'react-bootstrap-dialog';

import axios from 'axios';
import {
    Panel,
    Col,
    Row,
    Button,
    FormControl,
    InputGroup,
    FormGroup
} from 'react-bootstrap';

import { translate } from 'react-i18next';

import './PlayerPages.css';

class PlayerPages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            userIsAuthorized: props.userIsAuthorized,
            pages: [],
            page: props.page,
            pageCount: 0,
            limit: 40,
            search: props.search,
            selected: props.selected - 1,
            showingModal: false
        };
    }

    componentDidMount() {
        this.loadPlayerFromServer();
        this.loadPlayerPagesFromServer();
    }

    copyPage(page) {
        this.setState({ showingModal: true });
	}

    loadOrganisationInformation() {
        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadPlayerFromServer() {
        var self = this;
        const { playerUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/players/${playerUUID}`, {})
            .then(function(response) {
                self.setState({
                    player_name: response.data.player.name
                });
                self.loadOrganisationInformation();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadPlayerPagesFromServer() {
        var self = this;
        const { uuid, playerUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/players/${playerUUID}/pages`, {
                params: {
                    page: self.state.page,
                    limit: self.state.limit,
                    search: self.state.search
                }
            })
            .then(function(response) {
                console.log(`Pages: ${JSON.stringify(response.data.pages)}`);
                self.setState({
                    pages: response.data.playerPages,
                    pageCount: response.data.pageCount
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        const url = new URL(window.location.href);

        if(this.state.search) {
            url.searchParams.set('search', this.state.search);
        } else {
            url.searchParams.delete('search');

        }
        window.history.pushState(null, null, url); 

        event.preventDefault();
        this.loadPlayerPagesFromServer();
    };

    handlePageClick = data => {
        let selected = data.selected + 1;

        const url = new URL(window.location.href);
        url.searchParams.set('page', selected);
        window.history.pushState(null, null, url); 

        this.setState({ page: selected, selected: data.selected }, () => {
            this.loadPlayerPagesFromServer();
        });
    };
    
    hideModal() {
        this.setState({ showingModal: false });
    }

    didConfirmCopy(playerUUID) {

        const pageUUID = this.state.pages[0].uuid;

        axios
            .post(`${config.baseURL}/players/${playerUUID}/apply/${pageUUID}`, {})
            .then(function(response) {
               console.log('Copied!');
            })
            .catch(function(err) {
                console.log('Error');
            });
    }

    didPickPlayer(player) {
        this.setState({ showingModal: false });

        const { t } = this.props;

        this.dialog.show({
            title: t('Dialog.warning'),
            body: (
				<div>
					{t('Dialog.sure_copy_page')}<br/>
					<ul><li><strong>{player.name}</strong></li></ul>
				</div>
			),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.copy'),
                    () => this.didConfirmCopy(player.uuid),
                    'btn-success'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }


    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'PlayerPages.title'
        )}`;

        const user = this.state.user;
        const { uuid, playerUUID } = this.props.match.params;

        return (
            <div className="PlayerPages">
                 <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />{' '}
                <PlayerPickerModal
                    show={this.state.showingModal}
                    onHide={this.hideModal.bind(this)}
                    organisationUUID={uuid}
                    excludePlayer={playerUUID}
                    chosePlayer={this.didPickPlayer.bind(this)}
                    singleSelection={true}
                />
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('PlayerPages.title')}{' '}
                                    <small>
                                        {this.state.player_name}{' '}
                                        <small style={{ whiteSpace: 'nowrap' }}>
                                            {this.state.organisation_name}
                                        </small>
                                    </small>
                                        <a
                                            id="previewButton"
                                            type="submit"
                                            className="btn btn-success header-button"
                                            target="_blank"
                                            href={`${
                                                config.playersBaseURL
                                            }/mediaserver/unit.php?playerUUID=${playerUUID}&preview=1`}
                                        >
                                            <em className="fa fa-eye fa-fw" />{' '}
                                            {t('Player.preview_player')}
                                        </a>

                                        <a
                                            id="previewButton"
                                            type="submit"
                                            className="btn btn-warning header-button"
                                            target="_blank"
                                            href={`${
                                                config.playersBaseURL
                                            }/mediaserver/unit.php?playerUUID=${playerUUID}&preview=1&ignoreRules=1`}
                                        >
                                            <em className="fa fa-eye fa-fw" />{' '}
                                            {t('Player.preview_player_all')}
                                        </a>

                                </h1>

                                <ol className="breadcrumb">
                                    <li>
                                        <i className="fa fa-tachometer-alt" />{' '}
                                        <Link to={`/organisations/${uuid}`}>
                                            {t('Dashboard.title')}
                                        </Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-tv" />{' '}
                                        <Link
                                            to={`/organisations/${uuid}/players`}
                                        >
                                            {t('SideNavigation.players')}
                                        </Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-tv" />{' '}
                                        <Link
                                            to={`/organisations/${uuid}/players/${playerUUID}`}
                                        >
                                            {t('OrganisationPlayer.title')}
                                        </Link>
                                    </li>
                                    <li className="active">
                                        <i className="fa fa-file" />{' '}
                                        {t('SideNavigation.pages')}
                                    </li>
                                </ol>

                                <PaginationPanel
                                    heading={
                                        <Row>
                                            <Col xs={12} className="text-right">
                                                <form
                                                    role="search"
                                                    onSubmit={this.handleSubmit}
                                                >
                                                    <FormGroup controlId="search">
                                                        <InputGroup>
                                                            <FormControl
                                                                type="text"
                                                                value={
                                                                    this.state
                                                                        .search
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                                placeholder={t(
                                                                    'Search'
                                                                )}
                                                            />
                                                            <InputGroup.Button>
                                                                <Button
                                                                    onClick={
                                                                        this
                                                                            .handleSubmit
                                                                    }
                                                                >
                                                                    <i className="glyphicon glyphicon-search" />
                                                                </Button>
                                                            </InputGroup.Button>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </form>
                                            </Col>
                                        </Row>
                                    }
                                    pagination={
                                        <ReactPaginate
                                            previousLabel={t(
                                                'Pagination.previous'
                                            )}
                                            nextLabel={t('Pagination.next')}
                                            breakLabel={<Link to="#">...</Link>}
                                            breakClassName={'break-me'}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={
                                                'pages pagination'
                                            }
                                            activeClassName={'active'}
                                            forcePage={this.state.selected}
                                        />
                                    }
                                >
                                    <PlayerPagesTable
                                        userIsAuthorized={
                                            this.state.userIsAuthorized
                                        }
                                        
                                        user={this.state.user}
                                        onPageCopyClicked={this.copyPage.bind(this)}
                                        pages={this.state.pages}
                                        uuid={uuid}
                                        playerUUID={playerUUID}
                                    />
                                </PaginationPanel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(PlayerPages);
