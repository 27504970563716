import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Button } from 'react-bootstrap';
import Moment from 'moment';
import Dialog from 'react-bootstrap-dialog';
import TooltipButton from './TooltipButton';
import Switch from 'react-switch';

Moment.locale('en');

class PlayerAttributesTable extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    didConfirmDelete(attribute) {
        this.props.onDelete(attribute);
    }


	handleSwitchChange(checked, attribute) {
		console.log('click: ' + attribute.uuid);
		this.props.onEnabledChanged(attribute, checked);
	}

    handleDeleteClicked(attribute) {
        const { t } = this.props;

        this.dialog.show({
            title: t('Dialog.warning'),
            body: t('Dialog.sure_deletion'),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.delete'),
                    () => this.didConfirmDelete(attribute),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    render() {
        const { t, uuid } = this.props;
        let attributeRows = this.props.attributes.map((attribute, index) => {
            return (
                <tr key={index}>
                    <td width="250">{attribute.name}</td>
                    <td width="80">
                        {attribute.default ? (
                            <span className="label label-success">
                                {t('PlayerAttributesTable.true')}
                            </span>
                        ) : (
                            <span className="label label-danger">
                                {t('PlayerAttributesTable.false')}
                            </span>
                        )}
                    </td>
                    <td width="150" className="hidden-xs">
                        {Moment(attribute.updatedAt).format('YYYY-MM-DD HH:mm')}
                    </td>
                    <td className="hidden-xs">
                        {Moment(attribute.createdAt).format('YYYY-MM-DD HH:mm')}
                    </td>
                    <td>
                        <Switch
                            onChange={(checked, e, id) => {
                                e.stopPropagation();

                                this.handleSwitchChange(checked, attribute);
                            }}
                            checked={attribute.enabled}
                            onColor={'#5db85d'}
                            id='normal-switch'
                        />
                    </td>
                    <td align="center">
                        <TooltipButton
                            id=""
                            className="btn-danger center"
                            tooltip={t('PlayerAttributesTable.removeattribute')}
                            onClick={() => this.handleDeleteClicked(attribute)}
                        >
                            <em className="fa fa-trash" />
                        </TooltipButton>
                    </td>
                </tr>
            );
        });

        return (
            <div id="PlayerAttributesTable" className="PlayerAttributesTable">
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th>{t('PlayerAttributesTable.name')}</th>
                            <th>{t('PlayerAttributesTable.default')}</th>
                            <th className="hidden-xs">
                                {t('PlayerAttributesTable.updated')}
                            </th>
                            <th className="hidden-xs">
                                {t('PlayerAttributesTable.created')}
                            </th>
                            <th width='150'>{t('PlayerAttributesTable.enabled')}</th>
                            <th>
                                <em className="fa fa-cog" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>{attributeRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(PlayerAttributesTable);
