import React, { Component } from 'react';
import { Table, FormControl } from 'react-bootstrap';
import { translate } from 'react-i18next';
import Moment from 'moment';
import './OrganisationUserTable.css';
import TooltipLink from './TooltipLink';
import Dialog from 'react-bootstrap-dialog';
import TooltipButton from './TooltipButton';

class OrganisationUserTable extends Component {
	constructor(props) {
		super(props);

	}


	
	componentDidMount() { }

	getRoles(user, uuid) {
		if (user.organisationRoles && user.organisationRoles[uuid]) {
			return user.organisationRoles[uuid].join(', ');
		}
		return '';
	}

	getHighestRole(user, uuid) {

		const roleMapping = {
			"staff_super_admin": 1,
			"staff_admin": 2,
			"staff_engineer": 3,
			"organisation_admin": 4,
			"organisation_user": 5,
			"organisation_publisher": 6
		};

		if (user.organisationRoles && user.organisationRoles[uuid]) {
			const role = user.organisationRoles[uuid][0];
			console.log(`user: ${user.uuid} role: ${role}`);
			return roleMapping[role] || -1;
		}

		console.log("Returning -1");
		return -1;
	}

	didConfirmDelete(user) {
		this.props.onDelete(user);
	}

	handleChange = (event, user) => {
		const role = event.target.value;
		console.log(`User ${user.firstName} ${user.lastName} to be assigned a new role: ${role}`);
		this.props.onUserRoleSelected(user, role);
	};

	handleDeleteClicked(user) {
		const { t } = this.props;

		this.dialog.show({
			title: t('Dialog.warning'),
			body: (
				<div>
					{t('Dialog.sure_deletion_organisationuser')}<br/>
					<ul><li>{user.firstName} {user.lastName} (<strong>{user.email}</strong>)</li></ul>
				</div>
			),
			actions: [
				Dialog.Action(
					t('Dialog.cancel'),
					() => console.log('Cancel!'),
					'btn-default'
				),
				Dialog.Action(
					t('Dialog.delete'),
					() => this.didConfirmDelete(user),
					'btn-danger'
				)
			],
			bsSize: 'large',
			onHide: dialog => {
				dialog.hide();
			}
		});
	}

	render() {
		const { t, uuid, users, user } = this.props;

		let userRows = users.map((user, index) => {

			console.log(`User: ${JSON.stringify(user)}`);
			return (
				<tr key={index}>
					<td width="75" className="hidden-xs hidden-sm">
						{user.firstName}
					</td>
					<td width="75" className="hidden-xs hidden-sm">
						{user.lastName}
					</td>
					<td width="75">{user.email}</td>
					<td width="75">

						{this.props.userIsAuthorized(this.props.user, ['staff_super_admin']) ? (
						<FormControl
							componentClass="select"
							placeholder="Choose..."
							value={this.getHighestRole(user, uuid)}
							onChange={(event) => this.handleChange(event, user)}
						>
						
								<option value="1">
									{t(
										'InviteUserForm.roles.staff_super_admin'
									)}
								</option>
							
								<option value="2">
									{t(
										'InviteUserForm.roles.staff_admin'
									)}
								</option>
							
				
								<option value="3">
									{t(
										'InviteUserForm.roles.staff_engineer'
									)}
								</option>
							
								<option value="4">
									{t(
										'InviteUserForm.roles.organisation_admin'
									)}
								</option>													
								<option value="5" defaultValue>
									{t(
										'InviteUserForm.roles.organisation_user'
									)}
								</option>													
								<option value="6">
									{t(
										'InviteUserForm.roles.organisation_publisher'
									)}
								</option>
							
						</FormControl>) : this.getRoles(user, uuid)}
						
					</td>
					<td width="13%" className="td-center">
						<TooltipLink className="btn btn-default" tooltip={t('UserPlayersTable.viewplayers')} to={`/organisations/${uuid}/users/${user.uuid}/players`}>
							<em className="fa fa-tv" /> {t('UserPlayersTable.players')}
						</TooltipLink>
						{this.props.userIsAuthorized(this.props.user, [
													'staff_super_admin',
													
							]) ? (
								<TooltipButton
									id="deleteButton"
									style={{ 'margin-left': '10px' }}
									className="btn-danger"
									tooltip={t(
										'OrganisationUsersTable.removeUser'
									)}
									onClick={() => this.handleDeleteClicked(user)}
								>
									<em className="fa fa-trash" />{' '}
									{t('AttributePlayersTable.remove')}
								</TooltipButton>
							) : null}
					</td>
				</tr>
			);
		});

		return (
			<div id="users">
				<Dialog
					ref={el => {
						this.dialog = el;
					}}
				/>
				<Table striped bordered condensed hover>
					<thead>
						<tr>
							<th className="hidden-xs hidden-sm">{t('OrganisationUsersTable.first_name')}</th>
							<th className="hidden-xs hidden-sm">{t('OrganisationUsersTable.last_name')}</th>
							<th>{t('OrganisationUsersTable.email')}</th>
							<th className="hidden-xs">{t('OrganisationUsersTable.role')}</th>
							{this.props.userIsAuthorized(user, ['staff_super_admin', 'staff_admin', 'organisation_admin']) ? (
								<th>
									<em className='fa fa-cog' />{' '}
								</th>
							) : null}
						</tr>
					</thead>
					<tbody>{userRows}</tbody>
				</Table>
			</div>
		);
	}
}

export default translate('translations')(OrganisationUserTable);
