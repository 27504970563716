import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Checkbox } from 'react-bootstrap';
import Moment from 'moment';
import config from '../config';
import { Link } from 'react-router-dom';

Moment.locale('en');

class AttributePickerTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            itemsSelected: [],
            attributes: this.props.attributes,
            singleSelection: false
        };
    }

    getCheckedState(attribute) {
        const selected = this.state.itemsSelected.includes(attribute.uuid);
        console.log(`selected: ${selected}`);
        return selected;
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            attributes: newProps.attributes,
            itemsSelected: newProps.itemsSelected,
            singleSelection: newProps.singleSelection
        });
    }

    handleCheckBoxChange = event => {
        const target = event.target;

        const checked = target.checked;
        const uuid = target.name;

        const attribute = this.state.attributes.find(attribute => {
            return attribute.uuid === uuid;
        });

        this.props.onSelectionChanged(attribute, checked);
    };

    render() {
        const { t, uuid } = this.props;
        let attributeRows = this.state.attributes.map((attribute, index) => {
            return (
                <tr key={index}>
                    <td width="20">
                        <Checkbox
                            checked={this.getCheckedState(attribute)}
                            name={attribute.uuid}
                            onChange={this.handleCheckBoxChange}
                        />
                    </td>
                     <td width="250"   onClick={(e) => {

let checked = this.getCheckedState(attribute);

this.props.onSelectionChanged(attribute, !checked);

}}>{attribute.name}</td>
                    <td width="80">
                        {attribute.default ? (
                            <span className="label label-success">
                                {t('OrganisationAttributesTable.true')}
                            </span>
                        ) : (
                            <span className="label label-danger">
                                {t('OrganisationAttributesTable.false')}
                            </span>
                        )}
                    </td>
                    <td width="150" className="hidden-xs">
                        {Moment(attribute.updatedAt).format('YYYY-MM-DD HH:mm')}
                    </td>
                    <td width="150" className="hidden-xs">
                        {Moment(attribute.createdAt).format('YYYY-MM-DD HH:mm')}
                    </td>
                   
                   
                </tr>
            );
        });

        return (
            <div id="attributes" className="attributeTable">
                <Table
                    striped
                    bordered
                    condensed
                    hover
                    style={{ margin: '0px' }}
                >
                    <thead>
                        <tr>
                            <th width="20" />
                            <th className="hidden-xs hidden-sm">
                                {t('AttributePickerTable.name')}
                            </th>
                            <th>{t('AttributePickerTable.default')}</th>
                            <th className="hidden-xs hidden-sm">
                                {t('AttributePickerTable.updated')}
                            </th>
                            <th className="hidden-xs hidden-sm">
                                {t('AttributePickerTable.created')}
                            </th>
                           
                        </tr>
                    </thead>
                    <tbody>{attributeRows}</tbody>
                </Table>
            </div>
        );
    }
}

export default translate('translations')(AttributePickerTable);
