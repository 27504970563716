import React, { Component } from 'react';
import { Row, Col, Modal, Form, FormControl } from 'react-bootstrap';
import { translate } from 'react-i18next';
import axios from 'axios';
import config from '../config';
import Organisations from '../containers/Organisations';

/**
 * @name TemplateMediaPicker
 * @component Modal for chosing a template image
 */
export class TemplateMediaPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedBackground: '',
            selectedLibraryUUID: '',
            mediaLibraries: '',
            selectedLibrary: { organisationUUID: null,
                                uuid:null }
        };
    }

    componentDidMount() {
        this.loadLibrariesFromServer();
    }

    getMediaLibraryFromUUID(uuid) {
        const { mediaLibraries } = this.state;

        for (var i = 0; i < mediaLibraries.length; i++) {
            if (mediaLibraries[i].uuid === uuid) {
                return mediaLibraries[i];
            }
        }
        return null;
    }

    /**
     * @name loadLibrariesFromServer()
     * @desc Requests organisations' MediaLibraries and adds the returned Libraries to state
     */
    loadLibrariesFromServer = () => {
        var self = this;

        axios
            .get(
                `${config.baseURL}/templatelibraries`, {
                    params: {
                        page: 1,
                        limit: 1000
                    }
                }
            )
            .then(function (response) {
                self.setState({ mediaLibraries: response.data.mediaLibraries });
                if (response.data.mediaLibraries.length > 0) {
                    self.setState(
                        {
                            selectedLibraryUUID:
                                response.data.mediaLibraries[0].uuid,
                                selectedLibrary: self.getMediaLibraryFromUUID(response.data.mediaLibraries[0].uuid)

                        },
                        () => {
                            console.log(
                                'Selected Library:' +
                                response.data.mediaLibraries[0].uuid
                            );
                            axios
                                .get(
                                    `${config.baseURL}/medialibraries/${
                                    response.data.mediaLibraries[0].uuid
                                    }/media`
                                )
                                .then(function (response) {
                                    console.log(JSON.stringify(response.data));
                                    self.setState({
                                        media: response.data.media
                                    });
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                        }
                    );
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    /**
     * @name handleSelectLibrary
     * @desc Sets the selectLibrary state to the form value, gets media from the selected media library
     * @param Event
     */
    handleSelectLibrary = e => {
        console.log('handle select library');
        this.setState(
            {
                selectedLibraryUUID: e.target.value,
                selectedLibrary: this.getMediaLibraryFromUUID(e.target.value)
            },
            () => {
                var self = this;
                axios
                    .get(
                        `${config.baseURL}/templatelibraries/${
                        self.state.selectedLibraryUUID
                        }/media`
                    )
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));
                        self.setState({ media: response.data.media });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        );
    };

    render() {
        const {
            modalShow,
            handleModalClose,
            handleModalSave,
            handleSelectBackground,
            t
        } = this.props;
        const { mediaLibraries, selectedLibraryUUID } = this.state;
        const libraryMap = Array.from(mediaLibraries);

        let organisationUUID = this.state.selectedLibrary.organisationUUID;

        let thumbs = [];
        if (selectedLibraryUUID !== '') {
            thumbs = this.state.media || [];
        }

        let self = this;

        return (
            <Modal
                show={modalShow}
                onHide={handleModalClose}
                dialogClassName="modal-80"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('TemplateMediaPicker.modal_title')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row id="modal-form-wrapper">
                            <Col id="modal-form">
                                <p style={{ fontWeight: '700' }}>
                                    {t('TemplateMediaPicker.modal_form_library')}
                                </p>
                                <FormControl
                                    componentClass="select"
                                    value={selectedLibraryUUID}
                                    onChange={e => self.handleSelectLibrary(e)}
                                >
                                    {libraryMap.map(elem => (
                                        <option
                                            value={elem.uuid}
                                            key={elem.name}
                                            onClick={e =>
                                                self.handleSelectLibrary(e)
                                            }
                                        >
                                            {elem.name}
                                        </option>
                                    ))}
                                </FormControl>
                            </Col>
                        </Row>
                        {selectedLibraryUUID !== '' ? (
                            <Row id="modal-bg-picker">
                                <p style={{ fontWeight: '700' }}>
                                    {t('TemplateMediaPicker.modal_form_thumbs')}
                                </p>
                                <div className={`modal-bg-thumbnail-group`}>
                                    {thumbs.map((media) => (
                                        <div key={media.uuid} className={`modal-bg-thumbnail`} onClick={() => handleSelectBackground(media.uuid, `${`${organisationUUID}/${selectedLibraryUUID}/${media.uuid}.${media.extension}`}`)}>
                                            <img src={`https://players.cloudsignage.me/mediaserver/thumbnails/${organisationUUID}/${selectedLibraryUUID}/${media.uuid}.jpg`}>
                                            </img><br/>
                                            <small>{`${media.name}`}</small>
                                        </div>
                                    ))}
                                </div>
                            </Row>
                        ) : null}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-success"
                        onClick={handleModalSave}
                    >
                        <i className="fas fa-close fa-lg" />
                        <strong>
                            {' '}
                            {t('TemplateMediaPicker.modal_button_close')}
                        </strong>
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default translate('translations')(TemplateMediaPicker);
