import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AppliedRoute from './components/AppliedRoute';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

import Home from './containers/Home';
import GoogleAuthSuccess from './containers/GoogleAuthSuccess';
import Engineers from './containers/Engineers';
import Organisations from './containers/Organisations';
import CreateOrganisation from './containers/CreateOrganisation';
import EditOrganisation from './containers/EditOrganisation';

import AttributePlayers from './containers/AttributePlayers';
import GlobalPagePlayers from './containers/GlobalPagePlayers';
import UserPlayers from './containers/UserPlayers';

import Players from './containers/Players';
import CreatePlayer from './containers/CreatePlayer';
import EditPlayer from './containers/EditPlayer';
import PlayerAttributes from './containers/PlayerAttributes';
import PlayerPages from './containers/PlayerPages';
import EditPage from './containers/EditPage';
import EditPageSnippet from './containers/EditPageSnippet';
import EditGlobalSnippet from './containers/EditGlobalSnippet';

import Page from './containers/Page';
import CreatePageSnippet from './containers/CreatePageSnippet';
import AddPageSnippet from './containers/AddPageSnippet';
import MediaSnippet from './containers/MediaSnippet';
import MediaSnippetMediaLibrary from './containers/MediaSnippetMediaLibrary';

import TextLibrarySnippet from './containers/TextLibrarySnippet';
import TextLibrarySnippetTextLibrary from './containers/TextLibrarySnippetTextLibrary';

import CreateGlobalSnippet from './containers/CreateGlobalSnippet';

import EditTemplateSnippet from './containers/EditTemplateSnippet';

import EditMediaText from './containers/EditMediaText';

import EditTextLibraryText from './containers/EditTextLibraryText';
import TextLibraryTextRules from './containers/TextLibraryTextRules';
import EditTextLibraryTextRule from './containers/EditTextLibraryTextRule';
import CreateTextLibraryText from './containers/CreateTextLibraryText';

import CreateTextLibraryTextRule from './containers/CreateTextLibraryTextRule';

import CreateGlobalPage from './containers/CreateGlobalPage';

import FactSnippet from './containers/FactSnippet';

import QuestionSnippet from './containers/QuestionSnippet';

import TextSnippet from './containers/TextSnippet';

import CreateText from './containers/CreateText';
import EditText from './containers/EditText';
import TextRules from './containers/TextRules';
import CreateTextRule from './containers/CreateTextRule';
import EditTextRule from './containers/EditTextRule';

import SnippetRules from './containers/SnippetRules';
import CreateSnippetRule from './containers/CreateSnippetRule';
import EditSnippetRule from './containers/EditSnippetRule';

import CreateMedia from './containers/CreateMedia';
import MediaRules from './containers/MediaRules';
import CreateMediaRule from './containers/CreateMediaRule';
import EditMediaRule from './containers/EditMediaRule';

import Users from './containers/Users';

import FactTypes from './containers/FactTypes';
import CreateFactType from './containers/CreateFactType';
import EditFactType from './containers/EditFactType';

import Facts from './containers/Facts';
import CreateFact from './containers/CreateFact';
import EditFact from './containers/EditFact';

import QAndA from './containers/QAndA';
import CreateQuestion from './containers/CreateQuestion';
import EditQuestion from './containers/EditQuestion';

import QuestionTypes from './containers/QuestionTypes';
import CreateQuestionType from './containers/CreateQuestionType';
import EditQuestionType from './containers/EditQuestionType';

import OTD from './containers/OTD';
import CreateOTD from './containers/CreateOTD';
import EditOTDFact from './containers/EditOTDFact';

import TemplateMediaLibraries from './containers/TemplateMediaLibraries';
import TemplateMediaLibrary from './containers/TemplateMediaLibrary';

// Organisational
import Organisation from './containers/Organisation';
import OrganisationGoogleAuth from './containers/OrganisationGoogleAuth';
import OrganisationUsers from './containers/OrganisationUsers';
import OrganisationPlayers from './containers/OrganisationPlayers';
import OrganisationInvites from './containers/OrganisationInvites';
import OrganisationMediaLibraries from './containers/OrganisationMediaLibraries';
import OrganisationTextLibraries from './containers/OrganisationTextLibraries';
import OrganisationPlayer from './containers/OrganisationPlayer';
import OrganisationAttributes from './containers/OrganisationAttributes';
import OrganisationSnippets from './containers/OrganisationSnippets';
import OrganisationPages from './containers/OrganisationPages';
import OrganisationSettings from './containers/OrganisationSettings';

import OrganisationNotificationRules from './containers/OrganisationNotificationRules';
import EditOrganisationNotificationRule from './containers/EditOrganisationNotificationRule';
import CreateOrganisationNotificationRule from './containers/CreateOrganisationNotificationRule';

import OrganisationNotificationEmailRules from './containers/OrganisationNotificationEmailRules';
import EditOrganisationNotificationEmailRule from './containers/EditOrganisationNotificationEmailRule';
import CreateOrganisationNotificationEmailRule from './containers/CreateOrganisationNotificationEmailRule';

import MediaLibraryRules from './containers/MediaLibraryRules';
import EditMediaLibraryRule from './containers/EditMediaLibraryRule';
import CreateMediaLibraryRule from './containers/CreateMediaLibraryRule';

import CreateAttribute from './containers/CreateAttribute';
import EditAttribute from './containers/EditAttribute';

import InviteUser from './containers/InviteUser';

import Login from './containers/Login';
import UserSettings from './containers/UserSettings';

import ResetPassword from './containers/ResetPassword';
import Forgotten from './containers/Forgotten';
import Register from './containers/Register';
import PageNotFound from './containers/PageNotFound';
import AcceptInvite from './containers/AcceptInvite';

import Terms from './containers/Terms';
import PrivacyPolicy from './containers/PrivacyPolicy';

import Authorization from './helpers/Authorization';

export default ({ childProps }) => {

	const querystring =  function(name, url = window.location.href) {
        name = name.replace(/[[]]/g, '\\$&');
    
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
        const results = regex.exec(url);
    
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
    
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

	childProps.page = querystring("page") || 1;
	childProps.selected = querystring("page") || 1;
	childProps.search = querystring("search") || '';


	const Publisher = Authorization(childProps.user, [
		'staff_super_admin',
		'staff_admin',
		'staff_engineer',
		'organisation_admin',
		'organisation_user',
		'organisation_publisher'
	]);

	const OrganisationUser = Authorization(childProps.user, [
		'staff_super_admin',
		'staff_admin',
		'organisation_admin',
		'organisation_user'
	]);

	const Admin = Authorization(childProps.user, [
		'staff_super_admin',
		'staff_admin',
		'organisation_admin'
	]);

	const StaffEngineer = Authorization(childProps.user, [
		'staff_engineer'
	]);

	const StaffAdmin = Authorization(childProps.user, [
		'staff_super_admin',
		'staff_admin'
	]);

	const SuperAdmin = Authorization(childProps.user, ['staff_super_admin']);

	return (
		<Switch>
			<AuthenticatedRoute
				path="/"
				exact
				component={Home}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/engineers"
				exact
				component={StaffEngineer(Engineers)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/google/auth/success"
				exact
				component={Publisher(GoogleAuthSuccess)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations"
				exact
				component={StaffAdmin(Organisations)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations/create"
				exact
				component={StaffAdmin(CreateOrganisation)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/edit"
				exact
				component={SuperAdmin(EditOrganisation)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations/:uuid"
				exact
				component={Organisation}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations/:uuid/settings"
				exact
				component={Admin(OrganisationSettings)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations/:uuid/users"
				exact
				component={Admin(OrganisationUsers)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations/:uuid/users/invites"
				exact
				component={Admin(OrganisationInvites)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations/:uuid/users/invite"
				exact
				component={Admin(InviteUser)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path='/organisations/:uuid/users/:userUUID/players'
				exact
				component={Admin(UserPlayers)}
				props={childProps}
			/>


			<AuthenticatedRoute
				path="/organisations/:uuid/players"
				exact
				component={Publisher(OrganisationPlayers)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations/:uuid/players/create"
				exact
				component={StaffAdmin(CreatePlayer)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/edit"
				exact
				component={Admin(EditPlayer)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/attributes"
				exact
				component={Admin(PlayerAttributes)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages"
				exact
				component={Publisher(PlayerPages)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID"
				exact
				component={Publisher(Page)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/edit"
				exact
				component={OrganisationUser(EditPage)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/snippets/create"
				exact
				component={OrganisationUser(CreatePageSnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/snippets/add"
				exact
				component={OrganisationUser(AddPageSnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/snippets/:snippetUUID/rules"
				exact
				component={Publisher(SnippetRules)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/snippets/:snippetUUID/rulegroups/:ruleGroupUUID/rules/create"
				exact
				component={OrganisationUser(CreateSnippetRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/snippets/:snippetUUID/rulegroups/:ruleGroupUUID/rules/:ruleUUID/edit"
				exact
				component={OrganisationUser(EditSnippetRule)}
				props={childProps}
			/>


			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/snippets/:snippetUUID/edit"
				exact
				component={OrganisationUser(EditPageSnippet)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/factsnippets/:snippetUUID"
				exact
				component={Publisher(FactSnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/templatesnippets/:snippetUUID"
				exact
				component={Publisher(EditTemplateSnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/qandasnippets/:snippetUUID"
				exact
				component={Publisher(QuestionSnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/textsnippets/:snippetUUID"
				exact
				component={Publisher(TextSnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/textsnippets/:snippetUUID/texts/create"
				exact
				component={OrganisationUser(CreateText)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/textsnippets/:snippetUUID/texts/:textUUID/edit"
				exact
				component={Publisher(EditText)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/textsnippets/:snippetUUID/texts/:textUUID/rules"
				exact
				component={Publisher(TextRules)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/textsnippets/:snippetUUID/texts/:textUUID/rulegroups/:ruleGroupUUID/rules/create"
				exact
				component={OrganisationUser(CreateTextRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/textsnippets/:snippetUUID/texts/:textUUID/rulegroups/:ruleGroupUUID/rules/:ruleUUID/edit"
				exact
				component={OrganisationUser(EditTextRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/mediasnippets/:snippetUUID"
				exact
				component={Publisher(MediaSnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/textlibrarysnippets/:snippetUUID"
				exact
				component={Publisher(TextLibrarySnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/mediasnippets/:snippetUUID/medialibraries/:mediaLibraryUUID"
				exact
				component={Publisher(MediaSnippetMediaLibrary)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/mediasnippets/:snippetUUID/medialibraries/:mediaLibraryUUID/media/:mediaUUID/texts"
				exact
				component={Publisher(EditMediaText)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/mediasnippets/:snippetUUID/medialibraries/:mediaLibraryUUID/media/:mediaUUID/rules"
				exact
				component={Publisher(MediaRules)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/mediasnippets/:snippetUUID/medialibraries/:mediaLibraryUUID/media/:mediaUUID/rulegroups/:ruleGroupUUID/rules/create"
				exact
				component={OrganisationUser(CreateMediaRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/mediasnippets/:snippetUUID/medialibraries/:mediaLibraryUUID/media/:mediaUUID/rulegroups/:ruleGroupUUID/rules/:ruleUUID/edit"
				exact
				component={OrganisationUser(EditMediaRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/mediasnippets/:snippetUUID/medialibraries/:mediaLibraryUUID/media/create"
				exact
				component={Publisher(CreateMedia)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/textlibrarysnippets/:snippetUUID/textlibraries/:textLibraryUUID"
				exact
				component={Publisher(TextLibrarySnippetTextLibrary)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/textlibrarysnippets/:snippetUUID/textlibraries/:textLibraryUUID/texts/:textUUID/texts"
				exact
				component={Publisher(EditTextLibraryText)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/textlibrarysnippets/:snippetUUID/textlibraries/:textLibraryUUID/texts/:textUUID/rules"
				exact
				component={Publisher(TextLibraryTextRules)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/textlibrarysnippets/:snippetUUID/textlibraries/:textLibraryUUID/texts/:textUUID/rulegroups/:ruleGroupUUID/rules/create"
				exact
				component={OrganisationUser(CreateTextLibraryTextRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/textlibrarysnippets/:snippetUUID/textlibraries/:textLibraryUUID/texts/:textUUID/rulegroups/:ruleGroupUUID/rules/:ruleUUID/edit"
				exact
				component={OrganisationUser(EditTextLibraryTextRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID/pages/:pageUUID/textlibrarysnippets/:snippetUUID/textlibraries/:textLibraryUUID/text/create"
				exact
				component={Publisher(CreateTextLibraryText)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/players/:playerUUID"
				exact
				component={Publisher(OrganisationPlayer)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations/:uuid/snippets"
				exact
				component={Publisher(OrganisationSnippets)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/snippets/create"
				exact
				component={Admin(CreateGlobalSnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/snippets/:snippetUUID/rules"
				exact
				component={Publisher(SnippetRules)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/snippets/:snippetUUID/rulegroups/:ruleGroupUUID/rules/create"
				exact
				component={OrganisationUser(CreateSnippetRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/snippets/:snippetUUID/rulegroups/:ruleGroupUUID/rules/:ruleUUID/edit"
				exact
				component={OrganisationUser(EditSnippetRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/snippets/:snippetUUID/edit"
				exact
				component={Admin(EditGlobalSnippet)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations/:uuid/templatesnippets/:snippetUUID"
				exact
				component={Publisher(EditTemplateSnippet)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations/:uuid/mediasnippets/:snippetUUID"
				exact
				component={Publisher(MediaSnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/mediasnippets/:snippetUUID/medialibraries/:mediaLibraryUUID"
				exact
				component={Publisher(MediaSnippetMediaLibrary)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/mediasnippets/:snippetUUID/medialibraries/:mediaLibraryUUID/media/:mediaUUID/texts"
				exact
				component={Publisher(EditMediaText)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/mediasnippets/:snippetUUID/medialibraries/:mediaLibraryUUID/media/:mediaUUID/rules"
				exact
				component={Publisher(MediaRules)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/mediasnippets/:snippetUUID/medialibraries/:mediaLibraryUUID/media/:mediaUUID/rulegroups/:ruleGroupUUID/rules/create"
				exact
				component={OrganisationUser(CreateMediaRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/mediasnippets/:snippetUUID/medialibraries/:mediaLibraryUUID/media/:mediaUUID/rulegroups/:ruleGroupUUID/rules/:ruleUUID/edit"
				exact
				component={OrganisationUser(EditMediaRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/textlibrarysnippets/:snippetUUID"
				exact
				component={Publisher(TextLibrarySnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/textlibrarysnippets/:snippetUUID/textlibraries/:textLibraryUUID"
				exact
				component={Publisher(TextLibrarySnippetTextLibrary)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/textlibrarysnippets/:snippetUUID/textlibraries/:textLibraryUUID/texts/:textUUID/texts"
				exact
				component={Publisher(EditTextLibraryText)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/textlibrarysnippets/:snippetUUID/textlibraries/:textLibraryUUID/texts/:textUUID/rules"
				exact
				component={Publisher(TextLibraryTextRules)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/textlibrarysnippets/:snippetUUID/textlibraries/:textLibraryUUID/texts/:textUUID/rulegroups/:ruleGroupUUID/rules/create"
				exact
				component={OrganisationUser(CreateTextLibraryTextRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/textlibrarysnippets/:snippetUUID/textlibraries/:textLibraryUUID/texts/:textUUID/rulegroups/:ruleGroupUUID/rules/:ruleUUID/edit"
				exact
				component={OrganisationUser(EditTextLibraryTextRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/textsnippets/:snippetUUID/texts/:textUUID/rules"
				exact
				component={Publisher(TextRules)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/textsnippets/:snippetUUID/texts/:textUUID/rulegroups/:ruleGroupUUID/rules/create"
				exact
				component={OrganisationUser(CreateTextRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/textsnippets/:snippetUUID/texts/:textUUID/rulegroups/:ruleGroupUUID/rules/:ruleUUID/edit"
				exact
				component={OrganisationUser(EditTextRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/mediasnippets/:snippetUUID/medialibraries/:mediaLibraryUUID/media/create"
				exact
				component={Publisher(CreateMedia)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/textlibrarysnippets/:snippetUUID/textlibraries/:textLibraryUUID/text/create"
				exact
				component={Publisher(CreateTextLibraryText)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid//:snippetUUID"
				exact
				component={Publisher(FactSnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/qandasnippets/:snippetUUID"
				exact
				component={Publisher(QuestionSnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/textsnippets/:snippetUUID"
				exact
				component={Publisher(TextSnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/textsnippets/:snippetUUID/texts/create"
				exact
				component={OrganisationUser(CreateText)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/textsnippets/:snippetUUID/texts/:textUUID/edit"
				exact
				component={OrganisationUser(EditText)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/medialibraries"
				exact
				component={Publisher(OrganisationMediaLibraries)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/medialibraries/:mediaLibraryUUID"
				exact
				component={Publisher(MediaSnippetMediaLibrary)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/medialibraries/:mediaLibraryUUID/media/:mediaUUID/texts"
				exact
				component={Publisher(EditMediaText)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/medialibraries/:mediaLibraryUUID/media/:mediaUUID/rules"
				exact
				component={Publisher(MediaRules)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/medialibraries/:mediaLibraryUUID/media/:mediaUUID/rulegroups/:ruleGroupUUID/rules/create"
				exact
				component={OrganisationUser(CreateMediaRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/medialibraries/:mediaLibraryUUID/media/:mediaUUID/rulegroups/:ruleGroupUUID/rules/:ruleUUID/edit"
				exact
				component={OrganisationUser(EditMediaRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/medialibraries/:mediaLibraryUUID/media/create"
				exact
				component={Publisher(CreateMedia)}
				props={childProps}
			/>

			<AuthenticatedRoute
					path="/organisations/:uuid/medialibraries/:mediaLibraryUUID/rules"
					exact
					component={Publisher(MediaLibraryRules)}
					props={childProps}
				/>

			<AuthenticatedRoute
					path="/organisations/:uuid/medialibraries/:mediaLibraryUUID/rulegroups/:ruleGroupUUID/rules/create"
					exact
					component={Publisher(CreateMediaLibraryRule)}
					props={childProps}
				/>

			<AuthenticatedRoute
				path="/organisations/:uuid/medialibraries/:mediaLibraryUUID/rulegroups/:ruleGroupUUID/rules/:ruleUUID/edit"
				exact
				component={Publisher(EditMediaLibraryRule)}
				props={childProps}
				/>
	
			<AuthenticatedRoute
				path="/organisations/:uuid/textlibraries"
				exact
				component={Publisher(OrganisationTextLibraries)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/textlibraries/:textLibraryUUID"
				exact
				component={Publisher(TextLibrarySnippetTextLibrary)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/textlibraries/:textLibraryUUID/texts/:textUUID/texts"
				exact
				component={Publisher(EditTextLibraryText)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/textlibraries/:textLibraryUUID/texts/:textUUID/rules"
				exact
				component={Publisher(TextLibraryTextRules)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/textlibraries/:textLibraryUUID/texts/:textUUID/rulegroups/:ruleGroupUUID/rules/create"
				exact
				component={OrganisationUser(CreateTextLibraryTextRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/textlibraries/:textLibraryUUID/texts/:textUUID/rulegroups/:ruleGroupUUID/rules/:ruleUUID/edit"
				exact
				component={OrganisationUser(EditTextLibraryTextRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/textlibraries/:textLibraryUUID/text/create"
				exact
				component={Publisher(CreateTextLibraryText)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/google/auth"
				exact
				component={OrganisationUser(OrganisationGoogleAuth)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations/:uuid/attributes"
				exact
				component={OrganisationUser(OrganisationAttributes)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/attributes/create"
				exact
				component={OrganisationUser(CreateAttribute)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations/:uuid/attributes/:attributeUUID/edit"
				exact
				component={OrganisationUser(EditAttribute)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/attributes/:attributeUUID/players"
				exact
				component={Admin(AttributePlayers)}
				props={childProps}
			/>


			<AuthenticatedRoute
				path="/organisations/:uuid/emailattributes"
				exact
				component={Admin(OrganisationAttributes)}
				props={childProps}
				otherProps={{emailAttribute: true}}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/emailattributes/create"
				exact
				component={OrganisationUser(CreateAttribute)}
				props={childProps}
				otherProps={{emailAttribute: true}}

			/>
			<AuthenticatedRoute
				path="/organisations/:uuid/emailattributes/:attributeUUID/edit"
				exact
				component={OrganisationUser(EditAttribute)}
				props={childProps}
				otherProps={{emailAttribute: true}}

			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/emailattributes/:attributeUUID/players"
				exact
				component={Admin(AttributePlayers)}
				props={childProps}
				otherProps={{emailAttribute: true}}

			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages"
				exact
				component={Publisher(OrganisationPages)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/create"
				exact
				component={Admin(CreateGlobalPage)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/players"
				exact
				component={Publisher(GlobalPagePlayers)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/edit"
				exact
				component={OrganisationUser(EditPage)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID"
				exact
				component={Publisher(Page)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/snippets/create"
				exact
				component={OrganisationUser(CreatePageSnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/snippets/add"
				exact
				component={OrganisationUser(AddPageSnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/snippets/:snippetUUID/edit"
				exact
				component={OrganisationUser(EditPageSnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/snippets/:snippetUUID/rules"
				exact
				component={Publisher(SnippetRules)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/snippets/:snippetUUID/rulegroups/:ruleGroupUUID/rules/create"
				exact
				component={OrganisationUser(CreateSnippetRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/snippets/:snippetUUID/rulegroups/:ruleGroupUUID/rules/:ruleUUID/edit"
				exact
				component={OrganisationUser(EditSnippetRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/factsnippets/:snippetUUID"
				exact
				component={Publisher(FactSnippet)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/templatesnippets/:snippetUUID"
				exact
				component={Publisher(EditTemplateSnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/qandasnippets/:snippetUUID"
				exact
				component={Publisher(QuestionSnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/textsnippets/:snippetUUID"
				exact
				component={Publisher(TextSnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/textsnippets/:snippetUUID/texts/create"
				exact
				component={OrganisationUser(CreateText)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/textsnippets/:snippetUUID/texts/:textUUID/edit"
				exact
				component={OrganisationUser(EditText)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/mediasnippets/:snippetUUID"
				exact
				component={Publisher(MediaSnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/mediasnippets/:snippetUUID/medialibraries/:mediaLibraryUUID"
				exact
				component={Publisher(MediaSnippetMediaLibrary)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/mediasnippets/:snippetUUID/medialibraries/:mediaLibraryUUID/media/:mediaUUID/texts"
				exact
				component={Publisher(EditMediaText)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/mediasnippets/:snippetUUID/medialibraries/:mediaLibraryUUID/media/:mediaUUID/rules"
				exact
				component={Publisher(MediaRules)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/mediasnippets/:snippetUUID/medialibraries/:mediaLibraryUUID/media/:mediaUUID/rulegroups/:ruleGroupUUID/rules/create"
				exact
				component={OrganisationUser(CreateMediaRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/mediasnippets/:snippetUUID/medialibraries/:mediaLibraryUUID/media/:mediaUUID/rulegroups/:ruleGroupUUID/rules/:ruleUUID/edit"
				exact
				component={OrganisationUser(EditMediaRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/mediasnippets/:snippetUUID/medialibraries/:mediaLibraryUUID/media/create"
				exact
				component={Publisher(CreateMedia)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/textlibrarysnippets/:snippetUUID"
				exact
				component={Publisher(TextLibrarySnippet)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/textlibrarysnippets/:snippetUUID/textlibraries/:textLibraryUUID"
				exact
				component={Publisher(TextLibrarySnippetTextLibrary)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/textlibrarysnippets/:snippetUUID/textlibraries/:textLibraryUUID/texts/:textUUID/texts"
				exact
				component={Publisher(EditTextLibraryText)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/textlibrarysnippets/:snippetUUID/textlibraries/:textLibraryUUID/texts/:textUUID/rules"
				exact
				component={Publisher(TextLibraryTextRules)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/textlibrarysnippets/:snippetUUID/textlibraries/:textLibraryUUID/texts/:textUUID/rulegroups/:ruleGroupUUID/rules/create"
				exact
				component={OrganisationUser(CreateTextLibraryTextRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/textlibrarysnippets/:snippetUUID/textlibraries/:textLibraryUUID/texts/:textUUID/rulegroups/:ruleGroupUUID/rules/:ruleUUID/edit"
				exact
				component={OrganisationUser(EditTextLibraryTextRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/textlibrarysnippets/:snippetUUID/textlibraries/:textLibraryUUID/text/create"
				exact
				component={Publisher(CreateTextLibraryText)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/textsnippets/:snippetUUID/texts/:textUUID/rules"
				exact
				component={Publisher(TextRules)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/textsnippets/:snippetUUID/texts/:textUUID/rulegroups/:ruleGroupUUID/rules/create"
				exact
				component={OrganisationUser(CreateTextRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/organisations/:uuid/pages/:pageUUID/textsnippets/:snippetUUID/texts/:textUUID/rulegroups/:ruleGroupUUID/rules/:ruleUUID/edit"
				exact
				component={OrganisationUser(EditTextRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
							path="/organisations/:uuid/notificationsettings/:settingsID/rules"
							exact
							component={Admin(OrganisationNotificationRules)}
							props={childProps}
						/>

			<AuthenticatedRoute
							path="/organisations/:uuid/notificationsettings/:settingsID/rules/create"
							exact
							component={Admin(CreateOrganisationNotificationRule)}
							props={childProps}
						/>

			<AuthenticatedRoute
				path="/organisations/:uuid/notificationsettings/:settingsID/rules/:ruleUUID/edit"
				exact
				component={Admin(EditOrganisationNotificationRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
							path="/organisations/:uuid/emails/:emailId/rules"
							exact
							component={Admin(OrganisationNotificationEmailRules)}
							props={childProps}
						/>

			<AuthenticatedRoute
							path="/organisations/:uuid/emails/:emailId/rules/create"
							exact
							component={Admin(CreateOrganisationNotificationEmailRule)}
							props={childProps}
						/>

			<AuthenticatedRoute
				path="/organisations/:uuid/emails/:emailId/rules/:ruleUUID/edit"
				exact
				component={Admin(EditOrganisationNotificationEmailRule)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/players"
				exact
				component={StaffAdmin(Players)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/users"
				exact
				component={StaffAdmin(Users)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/facts"
				exact
				component={StaffAdmin(Facts)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/facts/facttypes"
				exact
				component={StaffAdmin(FactTypes)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/facts/facttypes/create"
				exact
				component={StaffAdmin(CreateFactType)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/facts/facttypes/:facttypeID/edit"
				exact
				component={StaffAdmin(EditFactType)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/facts/create"
				exact
				component={StaffAdmin(CreateFact)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/facts/:factID/edit"
				exact
				component={StaffAdmin(EditFact)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/qanda"
				exact
				component={StaffAdmin(QAndA)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/qanda/questiontypes"
				exact
				component={StaffAdmin(QuestionTypes)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/qanda/questiontypes/create"
				exact
				component={StaffAdmin(CreateQuestionType)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/qanda/questiontypes/:questiontypeID/edit"
				exact
				component={StaffAdmin(EditQuestionType)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/qanda/create"
				exact
				component={StaffAdmin(CreateQuestion)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/qanda/:questionID/edit"
				exact
				component={StaffAdmin(EditQuestion)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/otd"
				exact
				component={StaffAdmin(OTD)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/otd/:otdID/edit"
				exact
				component={StaffAdmin(EditOTDFact)}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/otd/create"
				exact
				component={StaffAdmin(CreateOTD)}
				props={childProps}
			/>

			<UnauthenticatedRoute
				path="/login"
				exact
				component={Login}
				props={childProps}
			/>
			<AuthenticatedRoute
				path="/usersettings"
				exact
				component={SuperAdmin(UserSettings)}
				props={childProps}
			/>
			<Route
				path="/login/reset"
				exact
				component={ResetPassword}
				props={childProps}
			/>
			<Route
				path="/login/forgotten"
				exact
				component={Forgotten}
				props={childProps}
			/>
			<Route
				path="/register"
				exact
				component={Register}
				props={childProps}
			/>
			<Route
				path="/invite/accept"
				exact
				component={AcceptInvite}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/templatelibraries"
				exact
				component={StaffAdmin(TemplateMediaLibraries)}
				props={childProps}
			/>

			<AuthenticatedRoute	
				path="/templatelibraries/:mediaLibraryUUID"
				exact
				component={StaffAdmin(TemplateMediaLibrary)}
				props={childProps}
			/>

			<AuthenticatedRoute
				path="/templatelibraries/:mediaLibraryUUID/media/create"
				exact
				component={StaffAdmin(CreateMedia)}
				props={childProps}
			/>

			<Route
				path="/terms"
				exact
				component={Terms}
				props={childProps}
			/>

			<Route
				path="/privacy"
				exact
				component={PrivacyPolicy}
				props={childProps}
			/>

			{/* Add a 404 route to handle page not found */}
			<Route component={PageNotFound} />

		</Switch>
	);
};
