import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import PageSnippetsTable from '../components/PageSnippetsTable';
import { translate } from 'react-i18next';
import axios from 'axios';
import {
    Panel,
    Col,
    Row,
    Button,
    FormControl,
    Form,
    DropdownButton,
    MenuItem
} from 'react-bootstrap';
import HeaderFooterPanel from '../components/HeaderFooterPanel';
import config from '../config';
import qs from 'qs';
import reorder from '../helpers/reorder';
import { Link } from 'react-router-dom';

import './Page.css';

class Page extends Component {
    constructor(props) {
        super(props);
        const { uuid } = this.props.match.params;

        this.state = {
            user: props.user,
            userIsAuthorized: props.userIsAuthorized,
            page: null,
            uuid,
            snippets: []
        };
    }

    componentDidMount() {
        this.loadPageFromServer();
        this.loadPageSnippetsFromServer();
    }

    deleteSnippet(snippet) {
        const { uuid, pageUUID } = this.props.match.params;

        var self = this;
        axios
            .delete(
                `${config.baseURL}/pages/${pageUUID}/snippets/${snippet.uuid}`
            )
            .then(function(response) {
                self.loadPageSnippetsFromServer();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadPageSnippetsFromServer() {
        const { uuid, pageUUID } = this.props.match.params;

        var self = this;
        axios
            .get(`${config.baseURL}/pages/${pageUUID}/snippets`, {})
            .then(function(response) {
                self.setState({ snippets: response.data.snippets });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadPageFromServer() {
        const { uuid, pageUUID } = this.props.match.params;

        var self = this;
        axios
            .get(`${config.baseURL}/pages/${pageUUID}`, {})
            .then(function(response) {
                self.setState({ page: response.data.page });
                self.loadPlayerFromServer();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadPlayerFromServer() {
        var self = this;
        const { playerUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/players/${playerUUID}`, {})
            .then(function(response) {
                self.setState({
                    player_name: response.data.player.name
                });
                self.loadOrganisationInformation();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadOrganisationInformation() {
        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    reorderSnippets(result) {
        const { pageUUID } = this.props.match.params;

        const reorderedSnippets = reorder(
            this.state.snippets,
            result.source.index,
            result.destination.index
        );
        this.setState({
            snippets: reorderedSnippets
        });
        const ordering = {};
        for (var i = 0; i < reorderedSnippets.length; i++) {
            const snippet = reorderedSnippets[i];
            ordering[snippet.uuid] = i;
        }

        try {
            var self = this;
            axios
                .post(`${config.baseURL}/pages/${pageUUID}/snippets/reorder `, {
                    ordering
                })
                .then(function(response) {
                    console.log('Success!');
                })
                .catch(function(err) {
                    console.log(err);
                });
        } catch (e) {
            console.log('exception');
        }
    }

    handleSelect(eventKey, event) {
        console.log(`Chose: ${eventKey}`);
        const { uuid, playerUUID, pageUUID } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;

        if (eventKey === 'new') {
            let createLink = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/snippets/create?rel=player`;
            if (rel === 'page') {
                createLink = `/organisations/${uuid}/pages/${pageUUID}/snippets/create?rel=page`;
            }

            this.props.history.push(createLink);
        } else if (eventKey === 'existing') {
            let addLink = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/snippets/add?rel=player`;
            if (rel === 'page') {
                addLink = `/organisations/${uuid}/pages/${pageUUID}/snippets/add?rel=page`;
            }

            this.props.history.push(addLink);
        }
    }

    render() {
        const { t } = this.props;
        const { uuid, playerUUID, pageUUID } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;
        document.title = `MySign Digital Screen Solutions | ${t('Page.title')}`;

        return (
            <div className="Page">
                <div id="wrapper">
                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('Page.title')}{' '}
                                    <small>
                                        {this.state.player_name}{' '}
                                        <small style={{ whiteSpace: 'nowrap' }}>
                                            {this.state.organisation_name}
                                        </small>
                                    </small>
                                    {this.state.userIsAuthorized(
                                        this.state.user,
                                        [
                                            'staff_super_admin',
                                            'staff_admin',
                                            'organisation_admin',
                                            'organisation_user'
                                        ]
                                    ) ? (
                                        <DropdownButton
                                            bsStyle={'primary'}
                                            title={
                                                <span>
                                                    <em className="fa fa-plus fa-fw" />{' '}
                                                    {t('Page.add_snippet')}
                                                </span>
                                            }
                                            onSelect={this.handleSelect.bind(
                                                this
                                            )}
                                        >
                                            <MenuItem eventKey="new">
                                                {t('Page.create_new')}
                                            </MenuItem>
                                            
                                            <MenuItem eventKey="existing">
                                                {t('Page.choose_existing')}
                                            </MenuItem>
                                        </DropdownButton>
                                    ) : null}
                                    {rel === 'player' ? (
                                        <>
                                        <a
                                            id="previewButton"
                                            type="submit"
                                            className="btn btn-success header-button"
                                            target="_blank"
                                            href={`${
                                                config.playersBaseURL
                                            }/mediaserver/unit.php?playerUUID=${playerUUID}&preview=1`}
                                        >
                                            <em className="fa fa-eye fa-fw" />{' '}
                                            {t('Player.preview_player')}
                                        </a>

                                        <a
                                            id="previewButton"
                                            type="submit"
                                            className="btn btn-warning header-button"
                                            target="_blank"
                                            href={`${
                                                config.playersBaseURL
                                            }/mediaserver/unit.php?playerUUID=${playerUUID}&preview=1&ignoreRules=1`}
                                        >
                                            <em className="fa fa-eye fa-fw" />{' '}
                                            {t('Player.preview_player_all')}
                                        </a>

                                        </>
                                    ) : null}
                                </h1>
                                <ol className="breadcrumb">
                                    {rel === 'player' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players`}
                                                >
                                                    {t(
                                                        'SideNavigation.players'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}`}
                                                >
                                                    {t(
                                                        'OrganisationPlayer.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages`}
                                                >
                                                    {t('SideNavigation.pages')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-puzzle-piece" />{' '}
                                                {t('Page.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                    {rel === 'page' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${
                                                        this.state.uuid
                                                    }`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${
                                                        this.state.uuid
                                                    }/pages`}
                                                >
                                                    {t(
                                                        'SideNavigation.globalpages'
                                                    )}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fa fa-puzzle-piece" />{' '}
                                                {t('Page.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                </ol>

                                <HeaderFooterPanel title={'Snippets'}>
                                    <PageSnippetsTable
                                        userIsAuthorized={
                                            this.state.userIsAuthorized
                                        }
                                        user={this.state.user}
                                        snippets={this.state.snippets}
                                        uuid={uuid}
                                        playerUUID={playerUUID}
                                        pageUUID={pageUUID}
                                        rel={rel}
                                        onDelete={this.deleteSnippet.bind(this)}
                                        onDragEnd={this.reorderSnippets.bind(
                                            this
                                        )}
                                    />
                                </HeaderFooterPanel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(Page);
