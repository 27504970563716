import React, { Component } from 'react';
import { translate } from 'react-i18next';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
    FormControl,
    FormGroup,
    InputGroup,
    ControlLabel,
    Col,
    Row,
    Checkbox,
    Table,
    Button,
} from 'react-bootstrap';

import Moment from 'moment';
import Dialog from 'react-bootstrap-dialog';
import './MediaSnippetMediaLibrariesTable.css';
import TooltipButton from './TooltipButton';

Moment.locale('en');

class MediaSnippetMediaLibrariesTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedInput: 0,
        };
    }

    didConfirmDelete(medialibrary) {
        this.props.onDelete(medialibrary);
    }

    didSaveChanges(medialibrary, newName) {
        this.props.onSave(medialibrary, newName);
    }

    onBeforeDragStart = () => {
        this.setState({
            isDragging: true
        });
    };

    onDragEnd = result => {
        this.setState({
            isDragging: false
        });

        // dropped outside the list
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            return;
        }

        // no movement
        if (result.destination.index === result.source.index) {
            return;
        }

        this.props.onDragEnd(result);
    };

    handleEditClicked(medialibrary) {
        const { t } = this.props;

        this.dialog.show({
            body: t('OrganisationMediaLibrariesTable.medialibrary_name'),
            prompt: Dialog.TextPrompt({
                placeholder: t('OrganisationMediaLibrariesTable.name'),
                initialValue: medialibrary.name
            }),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.save'),
                    dialog => this.didSaveChanges(medialibrary, dialog.value),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handlePinChange = (event, library) => {
		const pin = event.target.value;
		console.log(`Library ${library.name} to be assigned a new pin: ${pin}`);
		this.props.onPinSelected(library, pin);
	};

    handleDeleteClicked(medialibrary) {
        const { t } = this.props;

        this.dialog.show({
            title: t('Dialog.warning'),
            body: t('Dialog.sure_deletion'),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('Dialog.delete'),
                    () => this.didConfirmDelete(medialibrary),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    componentDidMount() {}

    render() {
        
        const {
            t,
            uuid,
            pageUUID,
            playerUUID,
            medialibraries,
            snippet
        } = this.props;

        const getItemStyle = (isDragging, draggableStyle) => ({
            // some basic styles to make the items look a bit nicer
            userSelect: 'none',
            background: isDragging ? '#dddddd' : '',
            display: isDragging ? 'table' : '',
            ...draggableStyle
        });

        const getListStyle = isDraggingOver => ({
            borderCollapse: isDraggingOver ? 'separate' : 'collapse'
        });

        let medialibraryRows = medialibraries.map((medialibrary, index) => {
            let iconName = 'fas fa-images';

            let medialibraryLink = `/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}/mediasnippets/${
                snippet.uuid
            }/medialibraries/${medialibrary.uuid}?rel=player`;
            if (this.props.rel === 'snippet') {
                medialibraryLink = `/organisations/${uuid}/mediasnippets/${
                    snippet.uuid
                }/medialibraries/${medialibrary.uuid}?rel=snippet`;
            } else if (this.props.rel === 'page') {
                medialibraryLink = `/organisations/${uuid}/pages/${pageUUID}/mediasnippets/${
                    snippet.uuid
                }/medialibraries/${medialibrary.uuid}?rel=page`;
            }

            console.log(`medialibrary: ${JSON.stringify(medialibrary)}`);
            return (
                <Draggable
                    key={`${index}.${medialibrary.uuid}`}
                    draggableId={`${index}.${medialibrary.uuid}`}
                    index={index}
                >
                    {(provided, snapshot) => (
                        <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                            )}
                        >
                            <td width="10">
                                <i
                                    className="fa fa-bars"
                                    aria-hidden="true"
                                    style={{ color: '#d0d0d0' }}
                                />
                            </td>
                            <td width="500">
                                {medialibrary.global ? (
                                    <span className="label label-success">
                                        <i className={'fa fa-globe'} />{' '}
                                        {t(
                                            'MediaSnippetMediaLibrariesTable.global'
                                        )}
                                    </span>
                                ) : null}{' '}
                                <Link
                                  style={{
                                     alignItems: "center",
                                     textAlign: "left"
                                   }}
                                  className="label label-primary"
                                   to={medialibraryLink}>
                                    <i className={`${iconName}`} />{' '}
                                    {medialibrary.name}
                                </Link>
                            </td>
                            <td className="hidden-xs">
                                {Moment(medialibrary.updatedAt).format(
                                    'YYYY-MM-DD HH:mm'
                                )}
                            </td>
                            <td className="hidden-xs">
                                {Moment(medialibrary.createdAt).format(
                                    'YYYY-MM-DD HH:mm'
                                )}
                            </td>
                            <td className="hidden-xs">
                            <FormGroup controlId="selectedInput" bsSize="sm">
                            
                                       <FormControl
                                            componentClass="select"
                                            placeholder="Choose..."
                                            value={medialibrary.inputPin}
                                            onChange={(event) => this.handlePinChange(event, medialibrary)}
                                        >
                                            <option value="NONE">
                                                {t('MediaSnippetMediaLibrariesTable.inputs.none')}
                                            </option>
                                            <option value="PIN_1">
                                                {t('MediaSnippetMediaLibrariesTable.inputs.one')}
                                            </option>
                                            <option value="PIN_2">
                                                {t('MediaSnippetMediaLibrariesTable.inputs.two')}
                                            </option>
                                            <option value="PIN_3">
                                                {t('MediaSnippetMediaLibrariesTable.inputs.three')}
                                            </option>
                                        </FormControl>
                                    </FormGroup>
                            </td>
                            <td align="center">
                            
                                <TooltipButton
                                    id=""
                                    className="btn-default"
                                    tooltip={t(
                                        'MediaSnippetMediaLibrariesTable.editmedialibrary'
                                    )}
                                    onClick={() =>
                                        this.handleEditClicked(medialibrary)
                                    }
                                >
                                    <em className="fas fa-pencil-alt" />
                                </TooltipButton>
                                {this.props.userIsAuthorized(this.props.user, [
                                    'staff_super_admin',
                                    'staff_admin',
                                    'organisation_admin',
                                    'organisation_user'
                                ]) ? (
                                    <TooltipButton
                                        id="deleteButton"
                                        className="btn-danger pull-right"
                                        tooltip={t(
                                            'MediaSnippetMediaLibrariesTable.deletemedialibrary'
                                        )}
                                        onClick={() =>
                                            this.handleDeleteClicked(
                                                medialibrary
                                            )
                                        }
                                    >
                                        <em className="fa fa-trash" />
                                    </TooltipButton>
                                ) : null}
                            </td>
                        </tr>
                    )}
                </Draggable>
            );
        });

        return (
            <div
                id="MediaSnippetMediaLibrariesTable"
                className="MediaSnippetMediaLibrariesTable"
            >
                <Dialog
                    ref={el => {
                        this.dialog = el;
                    }}
                />

                <DragDropContext
                    onBeforeDragStart={this.onBeforeDragStart}
                    onDragEnd={this.onDragEnd}
                >
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <table
                                style={getListStyle(snapshot.isDraggingOver)}
                                ref={provided.innerRef}
                                className="table table-striped"
                            >
                                <thead>
                                    <tr>
                                        <th width="10"> </th>

                                        <th width="500">
                                            {t(
                                                'MediaSnippetMediaLibrariesTable.name'
                                            )}
                                        </th>

                                        <th className="hidden-xs">
                                            {t(
                                                'MediaSnippetMediaLibrariesTable.updated'
                                            )}
                                        </th>
                                        <th className="hidden-xs">
                                            {t(
                                                'MediaSnippetMediaLibrariesTable.created'
                                            )}
                                        </th>
                                        <th className="hidden-xs">
                                            {t(
                                                'MediaSnippetMediaLibrariesTable.inputPin'
                                            )}
                                        </th>
                                        <th>
                                            <em className="fa fa-cog" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>{medialibraryRows}</tbody>
                            </table>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    }
}

export default translate('translations')(MediaSnippetMediaLibrariesTable);
