import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import Navigation from '../components/Navigation';
import TextLibrarySnippetTextLibrariesTable from '../components/TextLibrarySnippetTextLibrariesTable';
import PaginationPanel from '../components/PaginationPanel';
import DismissableAlert from '../components/DismissableAlert';
import TextLibraryPickerModal from '../components/TextLibraryPickerModal';
import config from '../config';
import qs from 'qs';
import axios from 'axios';
import {
    Panel,
    Col,
    Row,
    Button,
    DropdownButton,
    MenuItem,
    FormControl,
    FormGroup,
    InputGroup
} from 'react-bootstrap';
import Dialog from 'react-bootstrap-dialog';
import { Link } from 'react-router-dom';
import reorder from '../helpers/reorder';

import { translate } from 'react-i18next';

import './TextLibrarySnippet.css';

class TextLibrarySnippet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            snippet: { name: '' },
            userIsAuthorized: props.userIsAuthorized,
            textlibraries: [],
            page: props.page,
            pageCount: 0,
            limit: 40,
            search: props.search,
            selected: props.selected - 1,
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            },
            showingModal: false
        };
    }

    saveTextLibrary(textlibrary, newName) {
        var self = this;
        axios
            .put(`${config.baseURL}/textlibraries/${textlibrary.uuid}`, {
                name: newName
            })
            .then(function(response) {
                self.loadTextLibrarySnippetTextLibrariesFromServer();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        const url = new URL(window.location.href);

        if(this.state.search) {
            url.searchParams.set('search', this.state.search);
        } else {
            url.searchParams.delete('search');

        }
        window.history.pushState(null, null, url); 

        event.preventDefault();
        this.loadTextLibrarySnippetTextLibrariesFromServer();
    };

    componentDidMount() {
        console.log('componentDidMount');
        this.loadTextLibrarySnippetFromServer();
        this.loadTextLibrarySnippetTextLibrariesFromServer();
    }

    loadOrganisationInformation() {
        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name
                });

                const parsed = qs.parse(
                    self.props.location.search.substring(1)
                );
                const rel = parsed.rel;
                if (rel === 'player') {
                    self.loadPlayerFromServer();
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadTextLibrarySnippetTextLibrariesFromServer() {
        var self = this;
        const { snippetUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/snippets/${snippetUUID}/textlibraries`, {
                params: {
                    page: self.state.page,
                    limit: self.state.limit,
                    search: self.state.search
                }
            })
            .then(function(response) {
                self.setState({
                    textlibraries: response.data.textLibraries,
                    pageCount: response.data.pageCount
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    reorderTextLibraries(result) {
        const { snippetUUID } = this.props.match.params;

        const reorderedTextLibraries = reorder(
            this.state.textlibraries,
            result.source.index,
            result.destination.index
        );
        this.setState({
            textlibraries: reorderedTextLibraries
        });

        const ordering = [];
        for (var i = 0; i < reorderedTextLibraries.length; i++) {
            const snippet = reorderedTextLibraries[i];

            let orderingObject = {
                textLibraryUUID: snippet.uuid,
                id: snippet.id,
                position: i
            };

            ordering.push(orderingObject);
        }

        try {
            var self = this;
            axios
                .post(
                    `${
                        config.baseURL
                    }/snippets/${snippetUUID}/textlibraries/reorder `,
                    { ordering }
                )
                .then(function(response) {
                    console.log('Success!');
                })
                .catch(function(err) {
                    console.log(err);
                });
        } catch (e) {
            console.log('exception');
        }
    }

    didClickAdd(newName) {
        const { t } = this.props;
        const { uuid, snippetUUID } = this.props.match.params;

        try {
            let self = this;
            axios
                .post(
                    `${config.baseURL}/organisations/${uuid}/textlibraries`,
                    {
                        name: newName
                    }
                )
                .then(function(response) {
                    // Add TextLibrary to the snippet
                    return axios.post(
                        `${
                            config.baseURL
                        }/snippets/${snippetUUID}/textlibraries`,
                        {
                            textLibraryUUIDs: [response.data.textlibrary.uuid]
                        }
                    );
                })
                .then(function(response) {
                    self.setState({
                        error: {
                            hidden: false,
                            title: `${t('CreateTextLibrary.success')}:`,
                            style: 'success',
                            message: `${t(
                                'CreateTextLibrary.created_message'
                            )}`
                        }
                    });

                    self.loadTextLibrarySnippetTextLibrariesFromServer();
                })
                .catch(function(err) {
                    console.log(
                        `Error: ${JSON.stringify(err.response.data.message)}`
                    );
                    self.setState({
                        error: {
                            hidden: false,
                            title: `${t('CreateTextLibrary.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                error: {
                    hidden: false,
                    title: `${t('CreateTextLibrary.error')}:`,
                    style: 'danger',
                    message: `${t('CreateTextLibrary.unknown_error')}`
                }
            });
        }
    }

    deleteTextLibrary(textlibrary) {
        const { snippetUUID } = this.props.match.params;

        var self = this;
        axios
            .delete(
                `${config.baseURL}/snippets/${snippetUUID}/textlibraries/${
                    textlibrary.uuid
                }/${textlibrary.id}`
            )
            .then(function(response) {
                self.loadTextLibrarySnippetTextLibrariesFromServer();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    handleChooseGlobalClicked() {
        this.setState({ showingModal: true });
    }

    hideModal() {
        this.setState({ showingModal: false });
    }

    didPickTextLibraries(items) {
        const { t } = this.props;

        this.setState({ showingModal: false });
        const { uuid, snippetUUID } = this.props.match.params;

        console.log(`Selected Items: ${JSON.stringify(items)}`);

        let self = this;
        axios
            .post(`${config.baseURL}/snippets/${snippetUUID}/textlibraries`, {
                textLibraryUUIDs: items
            })
            .then(function(response) {
                self.setState({
                    error: {
                        hidden: false,
                        title: `${t('CreateTextLibrary.success')}:`,
                        style: 'success',
                        message: `${t('CreateTextLibrary.created_message')}`
                    }
                });

                self.loadTextLibrarySnippetTextLibrariesFromServer();
            })
            .catch(function(err) {
                console.log(
                    `Error: ${JSON.stringify(err.response.data.message)}`
                );
                self.setState({
                    error: {
                        hidden: false,
                        title: `${t('CreateTextLibrary.error')}:`,
                        style: 'danger',
                        message: err.response.data.message
                    }
                });
            });
    }

    handleCreateClicked() {
        const { t } = this.props;

        this.dialog.show({
            body: t('OrganisationTextLibrariesTable.textlibrary_name'),
            prompt: Dialog.TextPrompt({
                placeholder: t('OrganisationTextLibrariesTable.name'),
                initialValue: ''
            }),
            actions: [
                Dialog.Action(
                    t('Dialog.cancel'),
                    () => console.log('Cancel!'),
                    'btn-default'
                ),
                Dialog.Action(
                    t('TextLibrarySnippet.add_textlibrary'),
                    dialog => this.didClickAdd(dialog.value),
                    'btn-danger'
                )
            ],
            bsSize: 'large',
            onHide: dialog => {
                dialog.hide();
            }
        });
    }

    loadPlayerFromServer() {
        var self = this;
        const { playerUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/players/${playerUUID}`, {})
            .then(function(response) {
                self.setState({
                    player_name: response.data.player.name
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadTextLibrarySnippetFromServer() {
        var self = this;
        const { uuid, snippetUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/snippets/${snippetUUID}`, {})
            .then(function(response) {
                self.setState({
                    snippet: response.data.snippet
                });
                self.loadOrganisationInformation();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    handlePageClick = data => {
        let selected = data.selected + 1;

        const url = new URL(window.location.href);
        url.searchParams.set('page', selected);
        window.history.pushState(null, null, url); 

        this.setState({ page: selected, selected: data.selected }, () => {

            this.loadTextLibrarySnippetTextLibrariesFromServer();
        });
    };

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'TextLibrarySnippet.title'
        )}`;

        const user = this.state.user;
        const {
            uuid,
            playerUUID,
            pageUUID,
            snippetUUID
        } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;

        return (
            <div className="TextLibrarySnippet">
                <div id="wrapper">
                    <Dialog
                        ref={el => {
                            this.dialog = el;
                        }}
                    />
                    <Navigation props={this.props} />
                    <TextLibraryPickerModal
                        show={this.state.showingModal}
                        onHide={this.hideModal.bind(this)}
                        organisationUUID={uuid}
                        onChoose={this.didPickTextLibraries.bind(this)}
                    />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {this.state.snippet.name}{' '}
                                    {rel === 'player' ? (
                                        <small>
                                            {this.state.player_name}{' '}
                                            <small
                                                style={{
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {this.state.organisation_name}
                                            </small>
                                        </small>
                                    ) : (
                                        <small>
                                            {this.state.organisation_name}
                                        </small>
                                    )}
                                    {this.state.userIsAuthorized(user, [
                                        'staff_super_admin',
                                        'staff_admin',
                                        'organisation_admin',
                                        'organisation_user'
                                    ]) ? (
                                        <DropdownButton
                                            bsStyle={'primary'}
                                            title={
                                                <span>
                                                    <em className="fa fa-plus fa-fw" />{' '}
                                                    {t(
                                                        'TextLibrarySnippet.add_textlibrary'
                                                    )}
                                                </span>
                                            }
                                        >
                                            <MenuItem
                                                eventKey="1"
                                                onClick={() =>
                                                    this.handleCreateClicked()
                                                }
                                            >
                                                {t('TextLibrarySnippet.create_new')}
                                            </MenuItem>
                                            <MenuItem
                                                eventKey="2"
                                                onClick={() =>
                                                    this.handleChooseGlobalClicked()
                                                }
                                            >
                                                {t(
                                                    'TextLibrarySnippet.choose_existing'
                                                )}
                                            </MenuItem>
                                        </DropdownButton>
                                    ) : null}
                                     {rel === 'player' ? (
                                        <>
                                        <a
                                            id="previewButton"
                                            type="submit"
                                            className="btn btn-success header-button"
                                            target="_blank"
                                            href={`${
                                                config.playersBaseURL
                                            }/mediaserver/unit.php?playerUUID=${playerUUID}&preview=1`}
                                        >
                                            <em className="fa fa-eye fa-fw" />{' '}
                                            {t('Player.preview_player')}
                                        </a>

                                        <a
                                            id="previewButton"
                                            type="submit"
                                            className="btn btn-warning header-button"
                                            target="_blank"
                                            href={`${
                                                config.playersBaseURL
                                            }/mediaserver/unit.php?playerUUID=${playerUUID}&preview=1&ignoreRules=1`}
                                        >
                                            <em className="fa fa-eye fa-fw" />{' '}
                                            {t('Player.preview_player_all')}
                                        </a>

                                        </>
                                    ) : null}
                                </h1>

                                <ol className="breadcrumb">
                                    {rel === 'player' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players`}
                                                >
                                                    {t(
                                                        'SideNavigation.players'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}`}
                                                >
                                                    {t(
                                                        'OrganisationPlayer.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/`}
                                                >
                                                    {t('SideNavigation.pages')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}?rel=player`}
                                                >
                                                    {' '}
                                                    {t('Page.title')}
                                                </Link>
                                            </li>{' '}
                                            <li className="active">
                                                <i className="fas fa-font" />{' '}
                                                {t('TextLibrarySnippet.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                    {rel === 'snippet' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />
                                                <Link
                                                    to={`/organisations/${uuid}/snippets`}
                                                >
                                                    {' '}
                                                    {t(
                                                        'SideNavigation.globalsnippets'
                                                    )}
                                                </Link>
                                            </li>{' '}
                                            <li className="active">
                                                <i className="fas fa-font" />{' '}
                                                {t('TextLibrarySnippet.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}

                                    {rel === 'page' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages`}
                                                >
                                                    {t(
                                                        'SideNavigation.globalpages'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages/${pageUUID}?rel=page`}
                                                >
                                                    {t('Page.title')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fas fa-font" />{' '}
                                                {t('TextLibrarySnippet.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                </ol>
                                <DismissableAlert
                                    title={this.state.error.title}
                                    message={this.state.error.message}
                                    hidden={this.state.error.hidden}
                                    style={this.state.error.style}
                                />
                                <PaginationPanel
                                    heading={
                                        <Row>
                                            <Col xs={12} className="text-right">
                                                <form
                                                    role="search"
                                                    onSubmit={this.handleSubmit}
                                                >
                                                    <FormGroup controlId="search">
                                                        <InputGroup>
                                                            <FormControl
                                                                type="text"
                                                                value={
                                                                    this.state
                                                                        .search
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                                placeholder={t(
                                                                    'Search'
                                                                )}
                                                            />
                                                            <InputGroup.Button>
                                                                <Button
                                                                    onClick={
                                                                        this
                                                                            .handleSubmit
                                                                    }
                                                                >
                                                                    <i className="glyphicon glyphicon-search" />
                                                                </Button>
                                                            </InputGroup.Button>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </form>
                                            </Col>
                                        </Row>
                                    }
                                    pagination={
                                        <ReactPaginate
                                            previousLabel={t(
                                                'Pagination.previous'
                                            )}
                                            nextLabel={t('Pagination.next')}
                                            breakLabel={<Link to="#">...</Link>}
                                            breakClassName={'break-me'}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={
                                                'textlibraries pagination'
                                            }
                                            activeClassName={'active'}
                                            forcePage={this.state.selected}
                                        />
                                    }
                                >
                                    <TextLibrarySnippetTextLibrariesTable
                                        userIsAuthorized={
                                            this.state.userIsAuthorized
                                        }
                                        user={this.state.user}
                                        uuid={uuid}
                                        rel={rel}
                                        textlibraries={
                                            this.state.textlibraries
                                        }
                                        snippet={this.state.snippet}
                                        playerUUID={playerUUID}
                                        pageUUID={pageUUID}
                                        onSave={this.saveTextLibrary.bind(
                                            this
                                        )}
                                        onDelete={this.deleteTextLibrary.bind(
                                            this
                                        )}
                                        onDragEnd={this.reorderTextLibraries.bind(
                                            this
                                        )}
                                    />
                                </PaginationPanel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(TextLibrarySnippet);
