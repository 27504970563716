import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import Navigation from '../components/Navigation';
import FactSnippetFactTypesTable from '../components/FactSnippetFactTypesTable';
import PaginationPanel from '../components/PaginationPanel';
import DismissableAlert from '../components/DismissableAlert';
import FactTypePickerModal from '../components/FactTypePickerModal';
import config from '../config';
import qs from 'qs';
import axios from 'axios';
import {
    Panel,
    Col,
    Row,
    Button,
    FormControl,
    FormGroup,
    InputGroup
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import reorder from '../helpers/reorder';

import { translate } from 'react-i18next';

import './FactSnippet.css';

class FactSnippet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            snippet: {},
            facttypes: [],
            userIsAuthorized: props.userIsAuthorized,
            facts: [],
            page: props.page,
            pageCount: 0,
            limit: 40,
            search: props.search,
            selected: props.selected - 1,
            error: {
                hidden: true,
                title: '',
                message: '',
                style: 'danger'
            },
            showingModal: false
        };
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = event => {
        const { t } = this.props;

        const url = new URL(window.location.href);

        if(this.state.search) {
            url.searchParams.set('search', this.state.search);
        } else {
            url.searchParams.delete('search');

        }
        window.history.pushState(null, null, url);

        event.preventDefault();
        this.loadFactSnippetFactTypesFromServer();
    };

    componentDidMount() {
        this.loadFactSnippetFromServer();
        this.loadFactSnippetFactTypesFromServer();
    }

    loadFactSnippetFactTypesFromServer() {
        var self = this;
        const { uuid, snippetUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/snippets/${snippetUUID}/facttypes`, {
                params: {
                    page: self.state.page,
                    limit: self.state.limit,
                    search: self.state.search
                }
            })
            .then(function(response) {
                self.setState({
                    facttypes: response.data.factTypes,
                    pageCount: response.data.pageCount
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    didClickAdd(newName) {
        const { t } = this.props;
        const { uuid, snippetUUID } = this.props.match.params;

        try {
            let self = this;
            axios
                .post(`${config.baseURL}/organisations/${uuid}/facts`, {
                    name: newName
                })
                .then(function(response) {
                    // Add Fact to the snippet
                    return axios.post(
                        `${config.baseURL}/snippets/${snippetUUID}/facts`,
                        {
                            factLibraryUUID: response.data.fact.uuid
                        }
                    );
                })
                .then(function(response) {
                    self.setState({
                        error: {
                            hidden: false,
                            title: `${t('CreateFact.success')}:`,
                            style: 'success',
                            message: `${t('CreateFact.created_message')}`
                        }
                    });

                    self.loadFactSnippetFactTypesFromServer();
                })
                .catch(function(err) {
                    console.log(
                        `Error: ${JSON.stringify(err.response.data.message)}`
                    );
                    self.setState({
                        error: {
                            hidden: false,
                            title: `${t('CreateFact.error')}:`,
                            style: 'danger',
                            message: err.response.data.message
                        }
                    });
                });
        } catch (e) {
            this.setState({
                error: {
                    hidden: false,
                    title: `${t('CreateFact.error')}:`,
                    style: 'danger',
                    message: `${t('CreateFact.unknown_error')}`
                }
            });
        }
    }

    deleteFactType(factType) {
        const { snippetUUID } = this.props.match.params;

        var self = this;
        axios
            .delete(
                `${config.baseURL}/snippets/${snippetUUID}/facttypes/${
                    factType.id
                }`
            )
            .then(function(response) {
                self.loadFactSnippetFactTypesFromServer();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    handleCreateClicked() {
        this.setState({ showingModal: true });
    }

    hideModal() {
        this.setState({ showingModal: false });
    }

    didPickFactTypes(items) {
        this.setState({ showingModal: false });
        const { uuid, snippetUUID } = this.props.match.params;

        var self = this;
        axios
            .post(`${config.baseURL}/snippets/${snippetUUID}/facttypes`, {
                factTypeIDs: items
            })
            .then(function(response) {
                self.loadFactSnippetFactTypesFromServer();
            })
            .catch(function(err) {
                console.log('Error');
            });
    }

    loadFactSnippetFromServer() {
        var self = this;
        const { uuid, snippetUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/snippets/${snippetUUID}`, {})
            .then(function(response) {
                self.setState({
                    snippet: response.data.snippet
                });

                self.loadOrganisationInformation();
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadOrganisationInformation() {
        var self = this;

        const { uuid } = this.props.match.params;

        axios
            .get(`${config.baseURL}/organisations/${uuid}`, {})
            .then(function(response) {
                self.setState({
                    organisation_name: response.data.organisation.name
                });

                const parsed = qs.parse(
                    self.props.location.search.substring(1)
                );
                const rel = parsed.rel;
                if (rel === 'player') {
                    self.loadPlayerFromServer();
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    loadPlayerFromServer() {
        var self = this;
        const { playerUUID } = this.props.match.params;

        axios
            .get(`${config.baseURL}/players/${playerUUID}`, {})
            .then(function(response) {
                self.setState({
                    player_name: response.data.player.name
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    handlePageClick = data => {
        let selected = data.selected + 1;

        const url = new URL(window.location.href);
        url.searchParams.set('page', selected);
        window.history.pushState(null, null, url);

        this.setState({ page: selected, selected: data.selected }, () => {
            this.loadFactSnippetFactTypesFromServer();
        });
    };

    render() {
        const { t } = this.props;
        document.title = `MySign Digital Screen Solutions | ${t(
            'FactSnippet.title'
        )}`;

        const user = this.state.user;
        const {
            uuid,
            playerUUID,
            pageUUID,
            snippetUUID
        } = this.props.match.params;

        const parsed = qs.parse(this.props.location.search.substring(1));
        const rel = parsed.rel;

        return (
            <div className="FactSnippet">
                <div id="wrapper">
                    <FactTypePickerModal
                        show={this.state.showingModal}
                        onHide={this.hideModal.bind(this)}
                        organisationUUID={uuid}
                        onChoose={this.didPickFactTypes.bind(this)}
                    />

                    <Navigation props={this.props} />
                    <div id="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <h1 className="page-header">
                                    {t('FactSnippet.title')}
                                    {' - '}
                                    {this.state.snippet.name}{' '}
                                    {rel === 'player' ? (
                                        <small>
                                            {this.state.player_name}{' '}
                                            <small
                                                style={{
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {this.state.organisation_name}
                                            </small>
                                        </small>
                                    ) : (
                                        <small>
                                            {this.state.organisation_name}
                                        </small>
                                    )}
                                    {this.state.userIsAuthorized(user, [
                                        'staff_super_admin',
                                        'staff_admin',
                                        'organisation_admin',
                                        'organisation_user'
                                    ]) ? (
                                        <Button
                                            id="editButton"
                                            className="btn-primary pull-left"
                                            onClick={() =>
                                                this.handleCreateClicked()
                                            }
                                        >
                                            <em className="fa fa-plus fa-fw" />{' '}
                                            {t('FactSnippet.add_facttype')}
                                        </Button>
                                    ) : null}
                                    {rel === 'player' ? (
                                        <>
                                        <a
                                            id="previewButton"
                                            type="submit"
                                            className="btn btn-success header-button"
                                            target="_blank"
                                            href={`${
                                                config.playersBaseURL
                                            }/mediaserver/unit.php?playerUUID=${playerUUID}&preview=1`}
                                        >
                                            <em className="fa fa-eye fa-fw" />{' '}
                                            {t('Player.preview_player')}
                                        </a>

                                        <a
                                            id="previewButton"
                                            type="submit"
                                            className="btn btn-warning header-button"
                                            target="_blank"
                                            href={`${
                                                config.playersBaseURL
                                            }/mediaserver/unit.php?playerUUID=${playerUUID}&preview=1&ignoreRules=1`}
                                        >
                                            <em className="fa fa-eye fa-fw" />{' '}
                                            {t('Player.preview_player_all')}
                                        </a>

                                        </>
                                    ) : null}
                                </h1>

                                <ol className="breadcrumb">
                                    {rel === 'player' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players`}
                                                >
                                                    {t(
                                                        'SideNavigation.players'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-tv" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}`}
                                                >
                                                    {t(
                                                        'OrganisationPlayer.title'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/`}
                                                >
                                                    {t('SideNavigation.pages')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />
                                                <Link
                                                    to={`/organisations/${uuid}/players/${playerUUID}/pages/${pageUUID}?rel=player`}
                                                >
                                                    {' '}
                                                    {t('Page.title')}
                                                </Link>
                                            </li>{' '}
                                            <li className="active">
                                                <i className="fas fa-info" />{' '}
                                                {t('FactSnippet.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                    {rel === 'snippet' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />
                                                <Link
                                                    to={`/organisations/${uuid}/snippets`}
                                                >
                                                    {' '}
                                                    {t(
                                                        'SideNavigation.globalsnippets'
                                                    )}
                                                </Link>
                                            </li>{' '}
                                            <li className="active">
                                                <i className="fas fa-info" />{' '}
                                                {t('FactSnippet.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                    {rel === 'page' ? (
                                        <React.Fragment>
                                            <li>
                                                <i className="fa fa-tachometer-alt" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}`}
                                                >
                                                    {t('Dashboard.title')}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-file" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages`}
                                                >
                                                    {t(
                                                        'SideNavigation.globalpages'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <i className="fa fa-puzzle-piece" />{' '}
                                                <Link
                                                    to={`/organisations/${uuid}/pages/${pageUUID}?rel=page`}
                                                >
                                                    {t('Page.title')}
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <i className="fas fa-info" />{' '}
                                                {t('FactSnippet.title')}
                                            </li>{' '}
                                        </React.Fragment>
                                    ) : null}
                                </ol>
                                <DismissableAlert
                                    title={this.state.error.title}
                                    message={this.state.error.message}
                                    hidden={this.state.error.hidden}
                                    style={this.state.error.style}
                                />
                                <PaginationPanel
                                    heading={
                                        <Row>
                                            <Col xs={12} className="">
                                                <h5>
                                                    {t('FactSnippet.title')}{' '}
                                                </h5>
                                            </Col>
                                        </Row>
                                    }
                                    pagination={
                                        <ReactPaginate
                                            previousLabel={t(
                                                'Pagination.previous'
                                            )}
                                            nextLabel={t('Pagination.next')}
                                            breakLabel={<Link to="#">...</Link>}
                                            breakClassName={'break-me'}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={
                                                'facts pagination'
                                            }
                                            activeClassName={'active'}
                                            forcePage={this.state.selected}
                                        />
                                    }
                                >
                                    <FactSnippetFactTypesTable
                                        userIsAuthorized={
                                            this.state.userIsAuthorized
                                        }
                                        user={this.state.user}
                                        uuid={uuid}
                                        facttypes={this.state.facttypes}
                                        snippet={this.state.snippet}
                                        playerUUID={playerUUID}
                                        pageUUID={pageUUID}
                                        onDelete={this.deleteFactType.bind(
                                            this
                                        )}
                                    />
                                </PaginationPanel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}

export default translate('translations')(FactSnippet);
