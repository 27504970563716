import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Table, Button, Modal } from 'react-bootstrap';
import './AttributePickerModal.css';
import AttributePickerTable from './AttributePickerTable.js';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import config from '../config';
import { Link } from 'react-router-dom';

class AttributePickerModal extends Component {
    constructor(props) {
        super(props);

        this.handleHide = this.handleHide.bind(this);

        this.state = {
            show: false,
            title: '',
            attributes: [],
            page: 1,
            pageCount: 0,
            limit: 20,
            search: '',
            organisationUUID: '',
            excludeAttribute: '',
            excludeGlobalPage: '',
            excludeUserPlayers: '',
            excludePlayer: '',
            itemsSelected: [],
            singleSelection: false,
            selectedAttribute: null

        };
    }

    handlePageClick = data => {
        let selected = data.selected + 1;

        this.setState({ page: selected }, () => {
            this.loadOrganisationAttributesFromServer();
        });
    };

    handleHide() {
        this.props.onHide();
        this.setState({
            itemsSelected: []
        });
    }

    handleChooseClicked() {
        if (this.state.singleSelection) {
            this.props.choseAttribute(this.state.selectedAttribute);
        } else {
            console.log("About to call onChoose");
            this.props.onChoose(this.state.itemsSelected);
        }

        this.setState({
            itemsSelected: []
        });
    }

    componentDidMount() {}

    loadOrganisationAttributesFromServer() {
        var self = this;

        axios
            .get(
                `${config.baseURL}/organisations/${
                    this.state.organisationUUID
                }/attributes`,
                {
                    params: {
                        page: self.state.page,
                        limit: self.state.limit,
                        excludePlayer: self.state.excludePlayer,
                        excludeAttribute: self.state.excludeAttribute,
                        excludePage: self.state.excludeGlobalPage,
                        excludeUserPlayers: self.state.excludeUserPlayers,
                        search: self.state.search
                    }
                }
            )
            .then(function(response) {
                self.setState({
                    attributes: response.data.attributes,
                    pageCount: response.data.pageCount
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    componentWillReceiveProps(newProps) {
        this.setState(
            {
                show: newProps.show,
                title: newProps.title,
                singleSelection: newProps.singleSelection,
                organisationUUID: newProps.organisationUUID,
                excludeAttribute: newProps.excludeAttribute || '',
                excludeGlobalPage: newProps.excludeGlobalPage || '',
                excludePlayer: newProps.excludePlayer || '',
                excludeUserPlayers: newProps.excludeUserPlayers || ''
            },
            () => {
                this.loadOrganisationAttributesFromServer();
            }
        );
    }

    selectAll(attributes) {
        let itemsSelected = this.state.itemsSelected;
        attributes = attributes.map(attribute => attribute.uuid);
        itemsSelected = [...itemsSelected, ...attributes];
        let selectedSet = new Set(itemsSelected);
        this.setState({
            itemsSelected: [...selectedSet]
        });
    }

    deselectAll(attributes) {
        let itemsSelected = this.state.itemsSelected;
        let attributeUUIDsToRemove = attributes.map(attribute => attribute.uuid);

        itemsSelected = itemsSelected.filter(function(uuid) {
          return !attributeUUIDsToRemove.includes(uuid);
        });

        let selectedSet = new Set(itemsSelected);

        this.setState({
            itemsSelected: [...selectedSet]
        });
    }

    selectionChanged(attribute, checked) {
        let itemsSelected = this.state.itemsSelected;

        let selectedAttribute = null;
        if (!this.state.singleSelection) {

            if (checked) {
                itemsSelected.push(attribute.uuid);
            } else {
                itemsSelected = itemsSelected.filter(
                    selectedItem => selectedItem !== attribute.uuid
                );
            }

        } else {
                 if (checked) {
                    itemsSelected = [];
                    itemsSelected.push(attribute.uuid);
                    selectedAttribute = attribute;
                } else {
                    itemsSelected = [];
                }
        }

        var selectedSet = new Set(itemsSelected);

        this.setState({
            itemsSelected: [...selectedSet],
            selectedAttribute
        });
    }

    render() {
        const { t } = this.props;
        return (
            <div className="AttributePickerModal">
                <Modal
                    show={this.state.show}
                    onHide={this.handleHide}
                    container={this}
                    aria-labelledby="contained-modal-title"
                    dialogClassName="AttributePickerModal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title">

                        {this.state.singleSelection == false ? (t('AttributePickerModal.title')) : (t('AttributePickerModal.title_single'))}
                            {' '}
                            <div className="btn-toolbar pull-right" style={{ "margin-right": "10px"}}>

                            {this.state.singleSelection == false ? (
                                <Button
                                    className="btn-primary btn-sm"
                                    disabled={false}
                                    onClick={() => this.selectAll(this.state.attributes)}
                                >
                                    <em className="far fa-check-square" />{' '}
                                    {t(
                                        'AttributePickerModal.select_all'
                                    )}
                                </Button>
                                ) : null
                            }
                            {this.state.singleSelection == false ? (
                                <Button
                                    className="btn-normal btn-sm"
                                    disabled={false}
                                    onClick={() => this.deselectAll(this.state.attributes)}
                                >
                                    <em className="far fa-square" />{' '}
                                    {t(
                                        'AttributePickerModal.deselect_all'
                                    )}
                                </Button>  ) : null
                            }
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ margin: '0px', padding: '0px' }}>

                        <AttributePickerTable
                            singleSelection={this.state.singleSelection}
                            itemsSelected={this.state.itemsSelected}
                            attributes={this.state.attributes}
                            onDelete={() => {}}
                            onSelectionChanged={this.selectionChanged.bind(
                                this
                            )}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="text-center">
                            <ReactPaginate
                                previousLabel={t('Pagination.previous')}
                                nextLabel={t('Pagination.next')}
                                breakLabel={<Link to="#">...</Link>}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={10}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        </div>
                        <div className="btn-toolbar pull-right">
                            <Button onClick={this.handleHide}>
                                {t('AttributePickerModal.close')}
                            </Button>
                            <Button
                                disabled={this.state.itemsSelected.length === 0}
                                className="btn-primary"
                                onClick={this.handleChooseClicked.bind(this)}
                            >
                                {this.state.singleSelection == false ? (
                                t('AttributePickerModal.add_selected')
                                ) : (t('AttributePickerModal.choose_selected')) }
                                {this.state.singleSelection == false ? (
                                ` (${this.state.itemsSelected.length})`
                                ) : null }
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default translate('translations')(AttributePickerModal);
